import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Layout from '../adminDashboard/layout';
import axiosInstance from 'src/config/api';
import { LoadingLottie } from '../lotties/loading';
import { Pagination } from '@mui/material';

interface ISupport {
  name: String;
  contactNo: String;
  email: String;
  desc: String;
  createdOn: String;
}

const TechSupport = () => {
  const [tableData, setTableData] = useState<ISupport[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const baseUrl = process.env.REACT_APP_API_HOST;

  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role !== 'SUPER_ADMIN') {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const result = await axiosInstance.get(
          `${baseUrl}/api/v1/support?pageSize=20&pageNumber=${page}`
        );
        const resultData = result?.data;
        setTotalPage((resultData?.total || 0) / 20 || 1);
        setLoader(false);

        setTableData(() => [...(resultData?.data || [])]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoader(false);
      }
    };

    fetchData();
  }, [page]);

  if (loader) {
    return <LoadingLottie height={'700px'} />;
  }

  return (
    <>
      <Layout contentBordered={false}>
        <Table responsive striped bordered hover>
          <thead>
            <th>Date</th>
            <th>Description</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact No</th>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr>
                <td>{row.createdOn}</td>
                <td>{row.desc}</td>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.contactNo}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br />
        <Pagination
          count={totalPage}
          page={page}
          onChange={(event, pageNumber) => setPage(pageNumber)}
        />
      </Layout>
    </>
  );
};

export default TechSupport;
