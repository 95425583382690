import React from 'react';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import styles from './interestedUserModal.module.css';
import * as XLSX from 'xlsx';

type Props = {
  interstedUsers: any;
  showInterestedUsers: boolean;
  setShowInterestedUsers: (value: boolean) => void;
};

export default function InterestedUserModal({
  interstedUsers,
  showInterestedUsers,
  setShowInterestedUsers,
}: Props) {
  const handleDownload = () => {
    const usersWithoutUserId = interstedUsers.map((user: any) => {
      const { userId, ...rest } = user;
      return rest;
    });

    const worksheet = XLSX.utils.json_to_sheet(usersWithoutUserId);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Interested Users');
    XLSX.writeFile(workbook, 'interested_users.xlsx');
  };

  return (
    <Modal
      show={showInterestedUsers}
      onHide={() => setShowInterestedUsers(false)}
      centered
      className={styles['interestedUserModal']}
    >
      <ModalBody>
        <h2 className={styles['modal-title']}>Interested Users</h2>
        <div className={styles['table-container']}>
          <table className={styles['user-table']}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Upvoted On</th>
              </tr>
            </thead>
            <tbody>
              {interstedUsers.map((upvotes: any) => (
                <tr key={upvotes.userId}>
                  <td style={{ minWidth: 'max-content' }}>{upvotes?.name}</td>
                  <td>{upvotes?.emailAddress}</td>
                  <td>{new Date(upvotes?.updatedOn).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles['modal-footer']}>
          <Button
            className={styles['close-button']}
            onClick={() => setShowInterestedUsers(false)}
          >
            Close
          </Button>
          <Button
            className={styles['download-button']}
            onClick={handleDownload}
          >
            Download as Excel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
