import React, { useEffect, useState } from 'react';
import {
  deleteComment,
  deleteCommentUpvote,
  getCommentReplies,
  postCommentUpvote,
} from 'src/services/post-service/post-service';
import styles from '../../../pages/question/question.module.css';
import AvatarRow from 'src/components/avatar-row/avatar-row';
import { formatDateStr } from 'src/utils/dateUtil';
import AttachmentList from 'src/components/attachment-list/attachment-list';
import {
  IComment,
  IReply,
} from 'src/services/post-service/post-service.interface';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import ReplyView from 'src/pages/question/reply';
import NewComment from 'src/components/post/new-comment';

export default function CommentListItem({
  currentPost,
  currentComment,
  onDelete,
  onEdit,
}: {
  currentPost: any;
  currentComment: IComment;
  onDelete: (commentId: string) => void;
  onEdit: (comment: IComment) => void;
}) {
  const loggedInUserId = localStorage.getItem('id') || '';
  const [showCommentMenu, setShowCommentMenu] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [pageNumber, setPageNumber] = useState(1);

  //comment
  const [comment, setComment] = useState<IComment>(currentComment);
  const [newComment, setNewComment] = useState<boolean>(false);
  const [updating, setUpdating] = useState(false);
  const self = comment?._userId._id.toString() === loggedInUserId;

  //reply
  const [showEditReply, setShowEditReply] = useState(false);
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const [selectedReplies, setSelectedReplies] = useState<Array<IReply>>();
  const [loadingReplies, seLoadingReplies] = useState(false);
  const [hasMoreReplies, setHasMoreReplies] = useState(true);
  const [editReply, setEditReply] = useState<IReply>();
  const haveCommented = selectedReplies?.find(
    ({ _userId }) => _userId?._id.toString() === loggedInUserId
  );

  const fetchPostCommentReplies = async () => {
    if (currentPost?._id) {
      seLoadingReplies(true);
      try {
        const reply = await getCommentReplies(
          currentPost._id,
          comment?._id,
          pageNumber
        );
        if (pageNumber === 1) {
          setSelectedReplies(reply?.data);
        } else {
          setSelectedReplies((prevReplies: any) => [
            ...prevReplies,
            ...reply.data,
          ]);
        }
        setHasMoreReplies(reply.data.length === 3);
      } catch (error) {
        console.error('Error fetching replies:', error);
      }
      seLoadingReplies(false);
    }
  };

  const handleDelete = async () => {
    if (currentPost) {
      setUpdating(true);
      await deleteComment(currentPost?._id, comment._id);
      onDelete(comment.id);

      setUpdating(false);
    }
  };

  //upvotes:
  const [loadingUserUpvotesvotes, setLoadingUserUpvotes] = useState(false);

  const isUpvoted = () =>
    comment?.upvotes?.some(
      (upvote) => upvote?._userId.toString() === loggedInUserId
    );

  const [isVoted, setIsVoted] = useState(isUpvoted);

  const addUpvote = async () => {
    try {
      if (currentPost) {
        setLoadingUserUpvotes(true);
        const upvote = await postCommentUpvote(currentPost._id, comment._id);

        setComment((prevComment: any) => ({
          ...prevComment,
          upvotes: prevComment?.upvotes?.concat(upvote),
          upvoteCount: (prevComment.upvoteCount || 0) + 1,
        }));

        setLoadingUserUpvotes(false);
      }
    } catch (error) {
      console.error('Error adding upvote:', error);
      setLoadingUserUpvotes(false);
    }
  };

  const removeUpvote = async () => {
    try {
      if (currentPost) {
        setLoadingUserUpvotes(true);
        await deleteCommentUpvote(currentPost._id, comment._id, loggedInUserId);

        setComment((prevComment) => ({
          ...prevComment,
          upvotes: prevComment?.upvotes?.filter(
            (upvote) => upvote._userId.toString() !== loggedInUserId
          ),
          upvoteCount: prevComment.upvoteCount
            ? prevComment.upvoteCount - 1
            : 0,
        }));

        setLoadingUserUpvotes(false);
      }
    } catch (error) {
      console.error('Error removing upvote:', error);
      setLoadingUserUpvotes(false);
    }
  };

  useEffect(() => {
    fetchPostCommentReplies();
  }, [pageNumber]);

  useEffect(() => {
    setComment(currentComment);
    fetchPostCommentReplies();
  }, [currentComment]);

  useEffect(() => {
    setIsVoted(() => isUpvoted());
  }, [comment?.upvotes]);

  return (
    <div key={comment?._id} className={styles.commentWrapper}>
      <div className={styles['commentUserDateWrapper']}>
        <AvatarRow showMembership data={comment._userId} />
        <span className={styles['commentDate']}>
          {formatDateStr(comment.createdOn)}
        </span>
      </div>
      <p
        className={styles.commentContent}
        dangerouslySetInnerHTML={{ __html: comment.content }}
      />
      {comment?.attachments?.length > 0 && (
        <AttachmentList attachments={comment?.attachments} />
      )}
      <hr className={styles['comment-hr']} />

      <div className={styles['item-interaction']}>
        <div className={styles['item-interaction-left']}>
          <Button
            className={styles['interaction-btn']}
            color="transparent"
            onClick={() => {
              setShowReplies(!showReplies);
              fetchPostCommentReplies();
            }}
          >
            <img
              src={
                haveCommented
                  ? '/images/commentedIcon.svg'
                  : '/images/commentIcon.svg'
              }
              height={16}
              alt=""
            />
            &nbsp;{currentPost?.type === 'WORK' ? 'Reply' : 'Comment'} (
            {comment.replyCount})
            {loadingReplies && (
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
            )}
          </Button>
          {currentPost?.type !== 'WORK' && (
            <Button
              className={styles['interaction-btn']}
              color="transparent"
              onClick={isVoted ? () => removeUpvote() : addUpvote}
            >
              <img
                src={
                  isVoted ? '/images/upvotedIcon.svg' : '/images/upvoteIcon.svg'
                }
                alt=""
              />
              &nbsp;Upvote ({comment.upvoteCount || 0})
              {loadingUserUpvotesvotes && (
                <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
              )}
            </Button>
          )}
        </div>
        <div className={styles['item-interaction-right']}>
          {(self ||
            ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
            <>
              <Dropdown
                className={styles.menuButton}
                isOpen={showCommentMenu}
                toggle={() => setShowCommentMenu((old) => !old)}
                direction="down"
              >
                <DropdownToggle>
                  {updating ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      style={{ height: '14px' }}
                    />
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => onEdit(comment)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      {showReplies && (
        <>
          <div>
            {!showEditReply && (
              <div>
                {selectedReplies
                  ?.sort(
                    (a: any, b: any) =>
                      new Date(a.updatedOn).getTime() -
                      new Date(b.updatedOn).getTime()
                  )
                  .map(
                    (reply) =>
                      editReply?._id !== reply._id && (
                        <ReplyView
                          key={reply._id}
                          onEdit={(reply) => {
                            setEditReply(() => reply);
                            setNewComment(true);
                            setShowEditReply(() => true);
                          }}
                          onDelete={(replyId) => {
                            setSelectedReplies((prevReplies) => {
                              return [
                                ...(prevReplies || []).filter(
                                  ({ _id }) => _id !== replyId
                                ),
                              ];
                            });
                            setComment((prevComment) => ({
                              ...prevComment,
                              replyCount: prevComment.replyCount
                                ? prevComment.replyCount - 1
                                : 0,
                            }));
                          }}
                          comment={comment}
                          selectedQues={currentPost}
                          reply={reply}
                        />
                      )
                  )}
              </div>
            )}

            {!newComment ? (
              <Input
                type="text"
                className={styles['add-answer-input']}
                placeholder={
                  currentPost?.type === 'WORK' ? 'Reply here' : 'Comment here'
                }
                onClick={() => setNewComment(true)}
              />
            ) : (
              <NewComment
                initialReplyData={editReply}
                onCreate={(reply) => {
                  setNewComment(false);
                  setSelectedReplies((prevReplies) => {
                    return [
                      { ...reply, _userId: user },
                      ...(prevReplies || []),
                    ];
                  });
                  if (editReply) {
                    setComment({
                      ...comment,
                    });
                  } else {
                    setComment({
                      ...comment,
                      replyCount: (comment.replyCount || 0) + 1,
                    });
                  }
                  setShowEditReply(() => false);
                }}
                onUpdateReply={(reply) => {
                  setSelectedReplies(undefined);
                  fetchPostCommentReplies();
                  setEditReply(undefined);
                }}
                isReply={currentPost?.type === 'WORK'}
                selectedComm={comment}
                selectedQues={currentPost}
              />
            )}
          </div>
          {hasMoreReplies && (
            <div className="text-center">
              {' '}
              <Button
                color="transparent"
                className={styles.loadMoreBtn}
                onClick={() => setPageNumber((prevPage) => prevPage + 1)}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ color: '#1a1a1a' }}
                />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
