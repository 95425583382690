import axios from 'axios';
import axiosInstance from 'src/config/api';
import { Pin } from '../post-service/post-service.interface';
import {
  IExperience,
  IExperienceResponse,
  IExpertiseResponse,
  IMembershipResponse,
  IUser,
  IUserResponse,
} from './user-service.interface';

export const fetchUsersbyMatch = async (
  searchTerm: string,
  search?: any
): Promise<IUserResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const params: {
    emailAddress?: string;
    name?: string;
    pageNumber?: number;
    pageSize?: number;
  } = {};

  if (searchTerm && searchTerm.includes('@')) {
    params.emailAddress = searchTerm;
  } else if (searchTerm) {
    params.name = searchTerm;
  }

  if (search?.pageNumber) {
    params.pageNumber = search.pageNumber;
  }
  if (search?.pageSize) {
    params.pageSize = search.pageSize;
  }

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/user?populate=memberships&populate=expertise&populate=experiences&isProfessional=true`,
      {
        params: { ...params, ...(search || {}) },
      }
    )
  ).data;
};

export const fetchUsers = async (userIds: string[]) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/user?populate=memberships&populate=expertise&populate=experiences&${userIds
        .map((id) => `_id=${id}`)
        .join('&')}`
    )
  ).data;
};

export const getFollowings = async (
  id: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const params: { pageNumber?: number; pageSize?: number } = {};

  if (pageNumber) {
    params.pageNumber = pageNumber;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/user/${id}/pin?type=USER`, {
      params,
    })
  ).data;
};
export const getFollowers = async (
  userId: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const params: { pageNumber?: number; pageSize?: number } = {};

  if (pageNumber) {
    params.pageNumber = pageNumber;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/user/pin/${userId}/followers?type=USER`,
      { params }
    )
  ).data;
};

export const fetchUserDetails = async (userId: string): Promise<IUser> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.get(`${baseUrl}/api/v1/user/${userId}`)).data;
};

export const fetchUserExpertise = async (
  userId: string
): Promise<IExpertiseResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.get(`${baseUrl}/api/v1/user/${userId}/expertise`))
    .data;
};

export const saveUserExpertise = async (
  userId: string,
  expertiseArr: string[]
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  for (const expertise of expertiseArr) {
    await axiosInstance.post(
      `${baseUrl}/api/v1/user/${userId}/expertise`,
      JSON.parse(expertise)
    );
  }

  return;
};

export const deleteUserExpertise = async (
  userId: string,
  expertiseIds: string[]
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  for (const expertiseId of expertiseIds) {
    await axiosInstance.delete(
      `${baseUrl}/api/v1/user/${userId}/expertise/${expertiseId}`
    );
  }

  return;
};

export const handleProfileUpdate = async (
  userId: string,
  key: string,
  value: any
): Promise<IUser> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.patch(`${baseUrl}/api/v1/user/${userId}`, {
      [key]: value,
    })
  ).data;
};

export const followUser = async (
  userId: string,
  followerId: string
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return axiosInstance.post(`${baseUrl}/api/v1/user/${userId}/pin`, {
    type: Pin.USER,
    userId: followerId,
  });
};

export const deleteFollowUser = async (
  userId: string,
  followingId: string
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return axiosInstance.delete(
    `${baseUrl}/api/v1/user/${userId}/pin/${followingId}/USER`
  );
};

export const fetchMembership = async (
  userId: string
): Promise<IMembershipResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/user/${userId}/membership`)
  ).data;
};

export const fetchExperience = async (
  userId: string
): Promise<IExperienceResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/user/${userId}/experience`)
  ).data;
};

export const postExperience = async (
  userId: string,
  name: string,
  designation: string,
  industry?: string,
  startedOn?: Date,
  endedOn?: Date,
  type?: string,
  experienceId?: string,
  jobDescription?: string
): Promise<IExperience> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  if (experienceId) {
    return (
      await axiosInstance.patch(
        `${baseUrl}/api/v1/user/${userId}/experience/${experienceId}`,
        {
          name,
          designation,
          industry,
          startedOn,
          endedOn,
          type,
          jobDescription,
        }
      )
    ).data;
  }
  return (
    await axiosInstance.post(`${baseUrl}/api/v1/user/${userId}/experience`, {
      name,
      designation,
      industry,
      startedOn,
      endedOn,
      type,
      jobDescription,
    })
  ).data;
};

export const deleteExperience = async (
  userId: string,
  experienceId: string
): Promise<IExperience> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/user/${userId}/experience/${experienceId}`
    )
  ).data;
};

export const createUser = async (payload: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/user`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createOtherUser = async (formData: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  const payload = {
    name: formData.name,
    emailAddress: formData.email,
    contactNumber: formData.phoneNumber,
    city: formData.selectedDistrict,
    state: formData.selectedState,
    picture: null,
    password: formData.password,
  };

  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/user`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAadhaarOtherUser = async (
  userId: string,
  requestId: string,
  aadhaarNumber: string,
  otp: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const payload = {
      requestId,
      aadhaarNumber,
      otp,
    };
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}`,
      payload
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchUser = async (params: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/api/v1/user?populate=memberships`,
      { params: params }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async (id: any, payload: any, token?: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${id}`,
      payload,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserProfession = async (
  id: any,
  authToken: any,
  payload: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axios.post(
      `${baseUrl}/api/v1/user/${id}/membership`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log('error while updateing the user', error);
  }
};

export const updateMembershipNumber = async (
  userId: any,
  authToken: any,
  name: any,
  number: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axios.patch(
      `${baseUrl}/api/v1/user/${userId}/membership`,
      { name, number },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while updating the membership number', error);
    throw error;
  }
};

export const updateUserState = async (
  userId: any,
  newState: any,
  authToken: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}/state`,
      { state: newState },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token here
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.log('Error updating user state:', error);
    if (axios.isAxiosError(error)) {
      // Now TypeScript knows this is an Axios error
      const message = error.response?.data?.message || 'Failed to update state';
      throw new Error(message);
    } else {
      // Handle non-Axios errors if necessary
      throw new Error('An unexpected error occurred');
    }
  }
};
export const updateUserCity = async (
  userId: any,
  newCity: any,
  authToken: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}/city`,
      { city: newCity },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token here
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.log('Error updating user state:', error);
    if (axios.isAxiosError(error)) {
      // Now TypeScript knows this is an Axios error
      const message = error.response?.data?.message || 'Failed to update state';
      throw new Error(message);
    } else {
      // Handle non-Axios errors if necessary
      throw new Error('An unexpected error occurred');
    }
  }
};
export const patchUserName = async (
  userId: any,
  username: string,
  authToken: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}/username`,
      { userName: username },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token here
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.log('Error updating user name:', error);
    if (axios.isAxiosError(error)) {
      // Now TypeScript knows this is an Axios error
      const message =
        error.response?.data?.message || 'Failed to update userName';
      throw new Error(message);
    } else {
      // Handle non-Axios errors if necessary
      throw new Error('An unexpected error occurred');
    }
  }
};
export const updateUserContactNumber = async (
  userId: any,
  contactNumber: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}/contactNumber`,
      { contactNumber: contactNumber }
    );
    return response.data;
  } catch (error: unknown) {
    console.log('Error updating user contactNumber:', error);
    if (axios.isAxiosError(error)) {
      // Now TypeScript knows this is an Axios error
      const message =
        error.response?.data?.message || 'Failed to update contactNumber';
      throw new Error(message);
    } else {
      // Handle non-Axios errors if necessary
      throw new Error('An unexpected error occurred');
    }
  }
};
export const updateUserMembership = async (
  userId: any,
  membershipId: string,
  payload: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/${userId}/membership/${membershipId}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log('error while updateing the user', error);
  }
};

export const deleteUserMembership = async (
  userId: any,
  membershipId: string,
  payload: any
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.delete(
      `${baseUrl}/api/v1/user/${userId}/membership/${membershipId}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log('error while deleting the user membership', error);
  }
};

export const userTokenCreation = async (payload: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/token`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log('error while logging in with user', error);
  }
};

export const getOtpForValidation = async (aadhar: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const otpPayload = {
      type: 'AADHAAR_NUMBER',
      aadhaarNumber: aadhar,
    };

    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/otp`,
      otpPayload
    );
    return response.data;
  } catch (error) {
    console.log('error while validation the user', error);
  }
};

export const uploadUserAttachment = async (payload: any, authToken: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axios.post(`${baseUrl}/api/v1/file`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
};

export const fetchCities = async (payload?: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = (
      await axiosInstance.post(`${baseUrl}/api/v1/user/city?`, payload)
    ).data;

    return response;
  } catch (error) {
    console.log('error while fetching cities of users', error);
  }
};

export const postReportUser = async (userId: string, description: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/report`, {
      userId,
      reason: description,
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
};

export const sendOTPForgotPassword = async (email: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const payload = {
    emailAddress: email,
    type: 'EMAIL_ADDRESS',
  };
  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/otp`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(
      (error.response.data?.message as string) ||
        'Please enter your Email Address'
    );
  }
};

export const sendOtpSignupNonProf = async (email: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const payload = {
    emailAddress: email,
    type: 'EMAIL_ADDRESS',
  };
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/otp/signup`,
      payload
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      (error.response.data?.message as string) ||
        'Please enter your Email Address'
    );
  }
};

export const changePasswordOTP = async (
  emailAddress: string,
  otp: string,
  password: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  const payload = {
    emailAddress,
    otp,
    password,
  };
  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/user/password`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(
      (error.response.data?.message as string) || 'could not change password'
    );
  }
};

export const handleInvite = async (emailId: string) => {
  const payload = { emailId };
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    await axiosInstance.post(`${baseUrl}/api/v1/user/invite`, payload);
  } catch (error) {
    console.error('Error sending invite:', error);
    throw error;
  }
};

export const fetchExperiencedUser = async (organization: any) => {
  const payload = { organization };
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/user/organization`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching experienced user:', error);
    throw error;
  }
};

export const fetchAllOrganisationNames = async (
  query: string,
  pageNumber: number,
  pageLimit: number
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const response = await axiosInstance.get(
      `${baseUrl}/api/v1/user/${query}/organization`,
      {
        params: {
          pageNumber,
          pageLimit,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching experienced user:', error);
    throw error;
  }
};

export const createUserService = async (
  title: string,
  link: string,
  content: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/services`, {
      title,
      link,
      content,
    });
    return response.data;
  } catch (error) {
    console.log('error while creating the user service item', error);
  }
  return;
};

export const getUserService = async (userId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.get(
      `${baseUrl}/api/v1/services/${userId}`
    );
    return response.data;
  } catch (error) {
    console.log('error while fetching user service list', error);
  }
  return;
};
export const deleteUserService = async (serviceId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.delete(
      `${baseUrl}/api/v1/services/service/${serviceId}`
    );
    return response.data;
  } catch (error) {
    console.log('error while fetching user service list', error);
  }
  return;
};

export const updateJobAlert = async (
  qualification: any,
  location: any,
  contractType: any
): Promise<any> => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    return (
      await axiosInstance.post(`${baseUrl}/api/v1/user/job-alert`, {
        qualification,
        location,
        contractType,
      })
    ).data;
  } catch (error) {
    console.log('error while updateing user job alert list', error);
  }
  return;
};

export const fetchJobAlert = async (userId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.get(
      `${baseUrl}/api/v1/user/alert/${userId}`
    );
    return response.data;
  } catch (error) {
    console.log('error while fetching user service list', error);
  }
  return;
};
