import { useEffect } from 'react';
import Layout from 'src/components/adminDashboard/layout';
import MainBody from 'src/components/adminDashboard/mainBody';
import styles from './dashboard.module.css';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role !== 'SUPER_ADMIN') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Layout contentBordered>
      <div className={styles['wc-dashboard']}></div>
      <MainBody />
    </Layout>
  );
};

export default AdminDashboard;
