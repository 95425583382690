import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import styles from './job-alert.module.css';
import SignupModal from '../stepper/SignupModal';
import {
  fetchJobAlert,
  updateJobAlert,
} from 'src/services/user-service/user-servicec';
import Select from 'react-select';
import districtData from 'src/geographicalDetails/district';
import classNames from 'classnames';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { fetchActiveQualifications } from 'src/services/job-service/job-service';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import { FailureLottie } from '../lotties/failure';

export default function JobAlert() {
  const [showModal, setShowModal] = useState(false);
  const [selectedQualifications, setSelectedQualifications] = useState<
    string[]
  >([]);
  const [signupModal, setSignupModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectValue, setSelectValue] = useState<any>(null);
  const [selectQualiValue, setSelectQualiValue] = useState<any>(null);
  const [allActiveQualification, setAllActiveQualification] = useState<
    string[]
  >([]);
  const [contractType, setContractType] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucess, setIsSucess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const loggedInUserId = localStorage.getItem('id');

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await updateJobAlert(
        selectedQualifications,
        selectedLocation,
        contractType
      );
      setIsLoading(false);
      setIsSucess(true);

      setTimeout(() => {
        setIsSucess(false);
        setShowModal(false);
      }, 2000);
    } catch (error) {
      console.error('Error updating job alert preferences:', error);
      setIsLoading(false);
      setIsFailure(true);

      setTimeout(() => {
        setIsFailure(false);
        setShowModal(false);
      }, 2000);
    }
  };

  const getUserJobAlert = async () => {
    if (loggedInUserId) {
      try {
        const response = await fetchJobAlert(loggedInUserId);
        if (response?.data?.jobAlerts?.qualification) {
          setSelectedQualifications(response?.data?.jobAlerts?.qualification);
        }
        if (response?.data?.jobAlerts?.location) {
          setSelectedLocation(response?.data?.jobAlerts?.location);
        }
        if (response?.data?.jobAlerts?.contractType) {
          setContractType(response?.data?.jobAlerts?.contractType);
        }
      } catch (error) {
        console.error('Error fetching job alerts:', error);
      }
    }
  };

  const locationDropdownOptions = Object.entries(districtData)
    .sort(([stateA], [stateB]) => stateA.localeCompare(stateB))
    .flatMap(([state, districts]) =>
      districts.map((district) => ({
        label: `${district} (${state})`,
        value: district,
      }))
    );

  const qualificationDropdownOptions = allActiveQualification.map((quali) => ({
    value: quali,
    label: quali,
  }));

  const handleQualificationChange = (selectedOption: any) => {
    if (
      selectedQualifications.length < 3 &&
      selectedOption &&
      !selectedQualifications?.includes(selectedOption.value)
    ) {
      setSelectedQualifications([
        ...selectedQualifications,
        selectedOption.value,
      ]);
      setSelectQualiValue(null);
    }
  };

  const handleLocationChange = (selectedOption: any) => {
    if (
      selectedLocation.length < 5 &&
      selectedOption &&
      !selectedLocation?.includes(selectedOption.value)
    ) {
      setSelectedLocation([...selectedLocation, selectedOption.value]);
      setSelectValue(null);
    }
  };

  const handleDeleteLocation = (locationToDelete: string) => {
    setSelectedLocation((prevSelected) =>
      prevSelected.filter((location) => location !== locationToDelete)
    );
  };

  const handleDeleteQualification = (qualiToDelete: string) => {
    setSelectedQualifications((prevSelected) =>
      prevSelected.filter((qualification) => qualification !== qualiToDelete)
    );
  };

  useEffect(() => {
    if (showModal) {
      getLatestQualifications();
      getUserJobAlert();
    }
  }, [showModal]);

  const initialQualification = [
    'Intern',
    'Accountant',
    'Semi-qualified CA',
    'Semi-qualified CS',
    'Semi-qualified CWA',
    'Chartered Accountant',
    'Company Secretary',
    'Cost Accountant',
    'Advocate (LLB)',
    'Advocate (LLM)',
    'Others',
  ];

  const getLatestQualifications = async () => {
    const allQualificationLocation = await fetchActiveQualifications();
    const qualificationResponse = allQualificationLocation?.jobQualification;
    const uniqueCombined = Array.from(
      new Set([...initialQualification, ...qualificationResponse])
    );
    setAllActiveQualification(uniqueCombined);
  };

  const handleContractTypeChange = (e: any) => {
    const value = e.target.value;

    setContractType((prevContractType: any[]) => {
      if (prevContractType?.includes(value)) {
        // Remove the selected contract type if it's already selected
        return prevContractType.filter((item) => item !== value);
      } else {
        // Add the selected contract type
        return [...(prevContractType || []), value];
      }
    });
  };

  return (
    <>
      {window.innerWidth > 700 ? (
        <Button
          className={styles['job-alert-btn']}
          onClick={() =>
            !loggedInUserId ? setSignupModal(true) : setShowModal(true)
          }
        >
          <FontAwesomeIcon icon={faBell} color="#ff0000" />
          &nbsp; &nbsp;Set Job / Work Alert
        </Button>
      ) : (
        <button
          className={styles['job-alert-btn-mobile']}
          onClick={() =>
            !loggedInUserId ? setSignupModal(true) : setShowModal(true)
          }
        >
          <FontAwesomeIcon icon={faBell} color="#000" />
        </button>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName={styles['job-alert-modal']}
      >
        <Modal.Header
          closeButton
          style={{ padding: '12px', display: 'flex', alignItems: 'center' }}
        >
          <h6
            className={classNames(
              'd-flex align-item-center',
              styles['alert-modal-title']
            )}
          >
            Set Job / Work Alert Preferences
          </h6>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <LoadingLottie height={410} />
          ) : isSucess ? (
            <SuccessLottie height={410} />
          ) : isFailure ? (
            <FailureLottie height={410} />
          ) : (
            <div>
              <div className="container">
                <Row>
                  <p className={styles['modal-description']}>
                    By setting up your preferences, you'll receive Job / Work
                    alerts that matches your selected preferences.
                  </p>
                </Row>
              </div>
              <div className="mt-2">
                <span className={styles['alert-modal-subtitle']}>
                  Preferred Contract Type :
                </span>
                <div className="container ">
                  <Row>
                    <div
                      className={classNames(
                        'd-flex align-item-center mt-2',
                        styles['alert-contrat-wrapper']
                      )}
                    >
                      {' '}
                      <div
                        className="form-check d-flex align-item-center "
                        style={{ gap: '6px' }}
                      >
                        <input
                          type="checkbox"
                          id="permanentRole"
                          name="tenure"
                          value="Permanent Role"
                          checked={contractType?.includes('Permanent Role')}
                          onChange={handleContractTypeChange}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="permanentRole"
                          className="form-check-label"
                        >
                          Job Posting / Vacancy
                        </label>
                      </div>
                      <div
                        className="form-check d-flex align-item-center"
                        style={{ gap: '6px' }}
                      >
                        <input
                          type="checkbox"
                          id="assignmentBased"
                          name="tenure"
                          value="Assignment Based"
                          checked={contractType?.includes('Assignment Based')}
                          onChange={handleContractTypeChange}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="assignmentBased"
                          className="form-check-label"
                        >
                          Work Assignments (Days / Months)
                        </label>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="mt-2">
                {' '}
                <span className={styles['alert-modal-subtitle']}>
                  Preferred Qualifications &nbsp;
                  <span
                    style={{ fontSize: '14px', fontWeight: 'normal' }}
                  >{`(Max. 3)`}</span>
                  :
                </span>
                <div className="container mt-2">
                  <Row>
                    <div style={{ flex: 1 }}>
                      <Select
                        className={
                          classNames('mb-3')

                          // styles['react-select-container']
                        }
                        options={qualificationDropdownOptions}
                        value={selectQualiValue}
                        onChange={handleQualificationChange}
                        placeholder="Select Qualification"
                        isSearchable
                        styles={{
                          container: (base) => ({ ...base, width: '100%' }),
                        }}
                      />
                    </div>
                    <div>
                      {selectedQualifications.length > 0 ? (
                        <div
                          className={classNames(
                            'd-flex g-3 align-item-center flex-wrap',
                            styles['selected-loaction-wrapper']
                          )}
                        >
                          {selectedQualifications.map(
                            (qualification, index) => (
                              <span key={index}>
                                {qualification}{' '}
                                <FontAwesomeIcon
                                  className={classNames(
                                    'mb-1',
                                    styles.closeIcon
                                  )}
                                  onClick={() =>
                                    handleDeleteQualification(qualification)
                                  }
                                  color="#dc3545"
                                  icon={faClose}
                                  size="xs"
                                />
                              </span>
                            )
                          )}
                        </div>
                      ) : (
                        <p>No qualifications selected yet.</p>
                      )}
                    </div>
                  </Row>
                </div>
              </div>
              <div className="mt-2">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: 'max-content',
                  }}
                >
                  <span className={styles['alert-modal-subtitle']}>
                    Preferred Locations &nbsp;
                    <span
                      style={{ fontSize: '14px', fontWeight: 'normal' }}
                    >{`(Max. 5)`}</span>
                    :
                  </span>
                  <div
                    className="form-check "
                    style={{ gap: '6px', alignItems: 'center' }}
                  >
                    <input
                      type="checkbox"
                      name="Remote"
                      id="Remote"
                      value="Remote"
                      checked={selectedLocation.includes('Remote')}
                      onChange={() => {
                        setSelectedLocation((prevSelected) => {
                          if (prevSelected.includes('Remote')) {
                            return prevSelected.filter(
                              (location) => location !== 'Remote'
                            );
                          } else {
                            return [...prevSelected, 'Remote'];
                          }
                        });
                      }}
                      className="form-check-input"
                    />
                    <span
                      style={{ margin: '0 16px 0 0' }}
                      className={styles['alert-modal-subtitle']}
                    >
                      Remote
                    </span>
                  </div>
                </div>
                <div className="container mt-2">
                  <Row>
                    <div
                      className="d-flex align-items-center w-100 mb-3"
                      style={{ gap: '12px' }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Select
                          options={locationDropdownOptions}
                          value={selectValue}
                          onChange={handleLocationChange}
                          placeholder="Select City"
                          isSearchable
                          styles={{
                            container: (base) => ({ ...base, width: '100%' }),
                          }}
                        />
                      </div>
                    </div>
                  </Row>

                  <div>
                    {selectedLocation.length > 0 ? (
                      <div
                        className={classNames(
                          'd-flex g-3 align-item-center flex-wrap',
                          styles['selected-loaction-wrapper']
                        )}
                      >
                        {selectedLocation.map((location, index) => (
                          <span key={index}>
                            {location}{' '}
                            <FontAwesomeIcon
                              className={classNames('mb-1', styles.closeIcon)}
                              onClick={() => handleDeleteLocation(location)}
                              color="#dc3545"
                              icon={faClose}
                              size="xs"
                            />
                          </span>
                        ))}
                      </div>
                    ) : (
                      <p>No locations selected yet.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-item-center w-100 px-2">
            <button
              className="nav-link"
              onClick={() => setShowModal(false)}
              style={{ textDecoration: 'underline' }}
            >
              No, Thank You
            </button>
            <button className="btn btn-secondary" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {signupModal && (
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
      )}
    </>
  );
}
