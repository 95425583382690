import React, { useState, useRef, useEffect } from 'react';
import {
  ScrollMode,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faDownload,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';
import styles from './pdfviewer.module.css';
import { Button } from 'reactstrap';

const PdfViewer = ({ currentPdf }: { currentPdf: string }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const fullScreenButtonRef = useRef<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageAspectRatio, setPageAspectRatio] = useState<number>(1.3);
  const downloadRef = useRef<any>();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
    jumpToPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    jumpToPage(currentPage - 1);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.height = `${
        containerRef.current.offsetWidth * pageAspectRatio
      }px`;
    }
  }, [pageAspectRatio, currentPdf]);

  const handleDocumentLoad = async (e: any) => {
    try {
      const firstPage = await e.doc.getPage(1);
      const viewport = firstPage.getViewport({ scale: 1 });
      const aspectRatio = viewport.height / viewport.width;
      setPageAspectRatio(aspectRatio); // Set dynamic aspect ratio
      setTotalPages(e.doc.numPages);
    } catch (error) {
      console.error('Error loading PDF page:', error);
    }
  };

  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <div className={styles['pdfViewer-parent']}>
      <div className={styles['pdf-flex-container']}>
        <div style={{ minWidth: '20px' }}>
          {currentPage > 0 && (
            <FontAwesomeIcon onClick={handlePreviousPage} icon={faAngleLeft} />
          )}
        </div>
        <div
          ref={containerRef}
          style={{
            width: '100%',
            overflow: 'hidden',
            maxWidth: '100%',
          }}
          onClick={() => {
            fullScreenButtonRef.current.click();
          }}
          className={styles['pdfViewerWrapper']}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={currentPdf}
              plugins={[pageNavigationPluginInstance, fullScreenPluginInstance]}
              initialPage={currentPage}
              onDocumentLoad={handleDocumentLoad}
              defaultScale={SpecialZoomLevel.PageFit}
              scrollMode={ScrollMode.Page}
              enableSmoothScroll={true}
            />{' '}
          </Worker>
        </div>
        <div style={{ minWidth: '20px' }}>
          {currentPage < totalPages - 1 && (
            <FontAwesomeIcon onClick={handleNextPage} icon={faAngleRight} />
          )}
        </div>
      </div>
      <div>
        <EnterFullScreen>
          {(props: RenderEnterFullScreenProps) => (
            <button
              ref={fullScreenButtonRef}
              onClick={props.onClick}
              style={{ display: 'none' }}
            >
              <FontAwesomeIcon icon={faExpand} />
            </button>
          )}
        </EnterFullScreen>
      </div>

      <div className={styles['pdf-page-download-container']}>
        <div className={styles['page-download-wrapper']}>
          <span>
            Page {currentPage + 1} of {totalPages}
          </span>
          <Button
            onClick={() => downloadRef?.current?.click()}
            className={styles['download-btn']}
          >
            <FontAwesomeIcon icon={faDownload} size="sm" />
          </Button>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <a ref={downloadRef} href={currentPdf} download={currentPdf}></a>
      </div>
    </div>
  );
};

export default PdfViewer;
