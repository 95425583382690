import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../adminDashboard/layout';
import axiosInstance from 'src/config/api';

const AppNotification = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role !== 'SUPER_ADMIN') {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');

  const handlePushNotification = async () => {
    if (message.length > 0 && title.length > 0) {
      try {
        const baseUrl = process.env.REACT_APP_API_HOST;

        await axiosInstance.post(
          `${baseUrl}/api/v1/auth/google/sendnotification`,
          {
            message,
            title,
            url,
          }
        );
        setMessage('');
        setTitle('');
        setUrl('');
      } catch (error) {
        console.log('Error in sending Push NotiFication', error);
      }
    }
  };

  return (
    <Layout contentBordered={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          margin: '30px auto',
        }}
      >
        {' '}
        <input
          type="text"
          id="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          placeholder="Enter your title here"
          required
        />
        <textarea
          id="message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Enter your notification message here"
          required
        />
        <input
          type="text"
          id="url"
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder="Enter your url here"
          required
        />
        <button onClick={handlePushNotification}> Push NotiFication</button>
      </div>
    </Layout>
  );
};

export default AppNotification;
