export const trimWorld = (text: string, wordLength: number) => {
  const words = text.split(' ');

  if (words.length >= 50) {
    return `${words.slice(0, wordLength).join(' ')}...`;
  }
  return text;
};

export const getMembershipColor = (name: string) => {
  switch (name) {
    case 'ICAI':
      return '#F2E1C0';
    case 'ICSI':
      return '#CDC0F2';
    case 'ICMAI':
      return '#C0F2CB';
    case 'BCI':
      return '#C0F2EC';
    default:
      return '#CCCCCC';
  }
};

export const getPdfName = (pdfName: String) => {
  const sanitizedPdfName = pdfName.replace(/\.pdf$/, '');
  if (sanitizedPdfName.length > 15) {
    const finalPdfName = `${sanitizedPdfName.slice(0, 15)}...pdf`;
    return finalPdfName;
  } else {
    const finalPdfName = `${sanitizedPdfName}.pdf`;
    return finalPdfName;
  }
};
