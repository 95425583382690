import Lottie from 'react-lottie';
import refreshJson from './refresh.json';

type Props = {
  height?: number;
};

export const RefreshLottie = ({ height }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: refreshJson,
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} />
    </div>
  );
};
