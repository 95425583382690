import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styles from './sidebar.module.css';
import {
  faDiamond,
  faEllipsisVertical,
  faPenToSquare,
  faSpinner,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useStorage } from 'src/context/storage.context';
import { UserContext } from 'src/context/user.context';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { IAttachment } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import {
  deleteFollowUser,
  fetchUserDetails,
  followUser,
  handleProfileUpdate,
  patchUserName,
  updateUserCity,
  updateUserContactNumber,
  updateUserState,
} from 'src/services/user-service/user-servicec';
import { resolveBEAsset } from 'src/utils/urls';
import { AvatarUpload } from '../avatar-upload';
import { MessageContext } from 'src/context/message.context';
import { Report } from '../report';
import UpgradeToProfessional from '../upgrade-to-professional/upgradeToProfessional';
import districtData from 'src/geographicalDetails/district';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LocationModal from '../userUpdateModal/LocationModal';
import ContactNumberModal from '../userUpdateModal/contactNumUpdate';
import { categoryMap } from '../navbar/search.helper';
import UserNameModal from '../userUpdateModal/userNameModal';
import Services from '../profile/service';

const ProfileSidebar = () => {
  const { fetchMessageFromId } = useContext(MessageContext);
  const { following, id, addFollowing, removeFollowing } =
    useContext(UserContext);
  const [showReportUser, setShowReportUser] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [loadingProfilePic, setLoadingProfilePic] = useState(false);
  const [loadingHideContactNumber, setLoadingHideContactNumber] =
    useState(false);
  const [loadingHideEmailAddress, setLoadingHideEmailAddress] = useState(false);
  const [loadingFollowing, setLoadingFollowing] = useState(false);
  const router = useNavigate();
  const { profilePicUpdated, setProfilePicUpdated } = useStorage();
  const [reportDropdownShow, setReportDropdownShow] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId');
  const loggedInUserID = localStorage.getItem('id');

  const userFollowings = following?.filter(({ type }: any) => type === 'USER');
  const followingId = userFollowings.find(
    (follow: any) => follow.userId === profileId
  )?._id;

  const getUserDetails = async (userId: string) => {
    setUser(await fetchUserDetails(userId));
  };

  const handleFollow = async (): Promise<void> => {
    if (profileId) {
      setLoadingFollowing(true);
      const { data: newFollowing } = await followUser(id, profileId);
      addFollowing(newFollowing);
      setLoadingFollowing(false);
    }
  };

  const handleUnFollow = async (followingId: string): Promise<void> => {
    setLoadingFollowing(true);
    const { data: newFollowing } = await deleteFollowUser(id, followingId);
    removeFollowing(newFollowing);
    setLoadingFollowing(false);
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId as string);
    }
  }, [profileId]);

  const updateUser = (updateUser: Partial<IUser>) => {
    const updatedUser = { ...user, ...updateUser, _id: id };
    //@ts-ignore
    setUser(updatedUser);

    let localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
    localStorageUser = { ...localStorageUser, ...updateUser };
    localStorage.setItem('user', JSON.stringify(localStorageUser));
  };

  const handleProfilePicUpdate = async (image: File | null) => {
    setLoadingProfilePic(true);
    if (image) {
      const attachmentsArr = await uploadAttachment([image]);
      const picture: IAttachment = attachmentsArr?.[0];
      await handleProfileUpdate(id, 'picture', attachmentsArr[0]);
      if (picture) {
        updateUser({ picture });
        setProfilePicUpdated(profilePicUpdated + 1);
      }
    }
    setLoadingProfilePic(false);
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleView = (key: string) => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.set('view', key);
    urlParams.delete('postId');

    navigate({
      search: createSearchParams(urlParams).toString(),
    });
  };

  const goToChatWindow = async (data: any) => {
    await fetchMessageFromId(id, data);
  };

  const view = searchParams.get('view') || '';
  const token = localStorage.getItem('token') || '';
  const [contactNumberModal, setContactNumberModal] = useState(false);
  const [updateContactNumber, setUpdateContactNumber] = useState('');

  const handleLocationUpdate = async (state: string, city: string) => {
    if (state.trim() && city.trim()) {
      try {
        await Promise.all([
          updateUserCity(loggedInUserID, city.trim(), token),
          updateUserState(loggedInUserID, state.trim(), token),
        ]);
        if (profileId) {
          await getUserDetails(profileId as string);
        } else {
          await getUserDetails(loggedInUserID as string);
        }
      } catch (error) {
        console.error('Failed to update location:', error);
      }
    }
  };

  const handleContactNumberUpdate = async () => {
    if (updateContactNumber.trim()) {
      await updateUserContactNumber(profileId, updateContactNumber.trim());
    }

    setContactNumberModal(false);
    setUpdateContactNumber('');

    window.location.reload();
  };
  const [userNameModal, setUserNameModal] = useState(false);
  const [updateUserName, setUpdateUserName] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const handleUserNameUpdate = async () => {
    if (updateUserName) {
      const formattedUserName = updateUserName
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');

      await patchUserName(profileId, formattedUserName, token);
    }

    setUserNameModal(false);
    setUpdateContactNumber('');

    window.location.reload();
  };
  const toggleModal = () => setModalOpen(!modalOpen);
  useEffect(() => {
    if (selectedState) {
      setCities(districtData[selectedState]);
      setSelectedCity(''); // Reset city selection when state changes
    }
  }, [selectedState]);

  return (
    <>
      <div className={styles['profile-sidebar-parent']}>
        <div className={styles['profile-sidebar']}>
          <div className={styles['profile-avatar']}>
            <img
              src={
                user?.picture?.path
                  ? resolveBEAsset(`${user.picture?.path}`)
                  : '/images/defaultProfilePic.svg'
              }
              alt="user"
              className={styles['avatar-img']}
              width={0}
              height={0}
            />
            {profileId === id && (
              <AvatarUpload
                onProcessed={(file) => handleProfilePicUpdate(file)}
              >
                <Button color="transparent" className={styles['edit-btn']}>
                  {loadingProfilePic && (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                      &nbsp;
                    </>
                  )}
                  Edit <img alt="icon" src="/images/editWhiteIcon.png" />
                </Button>
              </AvatarUpload>
            )}
            {id !== profileId && (
              <Dropdown
                className={styles.reportContainer}
                isOpen={reportDropdownShow}
                toggle={() => setReportDropdownShow((old) => !old)}
                direction="down"
              >
                <DropdownToggle className={styles.reportButton}>
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </DropdownToggle>
                <DropdownMenu style={{ padding: '3px' }}>
                  <DropdownItem
                    style={{ padding: '5px', display: 'flex' }}
                    onClick={() => setShowReportUser(true)}
                  >
                    <img src="/images/reportIco.svg" alt="report" />
                    <span>&nbsp; Report User</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {id === profileId && !user?.isProfessional && (
              <Dropdown
                className={styles.reportContainer}
                isOpen={reportDropdownShow}
                toggle={() => setReportDropdownShow((old) => !old)}
                direction="down"
              >
                <DropdownToggle className={styles.reportButton}>
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </DropdownToggle>
                <DropdownMenu style={{ padding: '3px' }}>
                  <DropdownItem
                    style={{ padding: '5px', display: 'flex' }}
                    onClick={() => setShowUpgradeModal(true)}
                  >
                    <FontAwesomeIcon icon={faUserTie} />
                    <span>&nbsp; Professional Registration</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          <div
            className={classNames(
              [styles['user-details']],
              profileId === id ? [styles['self-user-details']] : ''
            )}
          >
            <h3>
              {user?.name}&nbsp;&nbsp;
              {window.location.href.includes('profile') &&
                profileId === loggedInUserID &&
                !user?.isProfessional && (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    onClick={() => setUserNameModal(true)}
                    style={{ cursor: 'pointer' }}
                    size="xs"
                  />
                )}
            </h3>
            {profileId === id ? (
              <p>
                {[user?.city, user?.state].filter((value) => value).join(', ')}
                &nbsp;&nbsp;
                {id === profileId && (
                  <div style={{ alignItems: 'center' }}>
                    {!user?.state && (
                      <span
                        style={{
                          borderBottom: '0.5px dotted #000',
                          cursor: 'pointer',
                        }}
                        onClick={() => setModalOpen(true)}
                      >
                        + Add Location
                      </span>
                    )}
                    &nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      onClick={() => setModalOpen(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </p>
            ) : user?.city && user.state ? (
              <p>
                {[user?.city, user?.state].filter((value) => value).join(', ')}
              </p>
            ) : null}

            {profileId !== id && user?.isProfessional && (
              <div className={styles.actionButtonContainer}>
                <Button
                  onClick={() => goToChatWindow(user)}
                  className={styles.actionButton}
                  color="primary"
                >
                  Message
                </Button>
                &nbsp;
                <Button
                  onClick={
                    followingId
                      ? () => handleUnFollow(followingId)
                      : handleFollow
                  }
                  outline
                  className={classNames(
                    styles.actionButton,
                    styles.actionButtonPrimary
                  )}
                >
                  {followingId ? 'Unfollow' : 'Follow'}
                  {loadingFollowing && (
                    <>
                      &nbsp;
                      <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>

          {(user?.emailAddress || user?.contactNumber) && (
            <div className={styles['user-contact']}>
              {user?.contactNumber && (
                <div className={styles['contact-info']}>
                  <span>
                    <img
                      alt="icon"
                      src="/images/phoneIcon.svg"
                      className={styles['phone-contact-img']}
                      style={{
                        marginRight: '8px',
                      }}
                    />
                    {user?.contactNumber}{' '}
                  </span>
                  {profileId === id && (
                    <div className={styles['phoneWrapper']}>
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        onClick={() => setContactNumberModal(true)}
                        style={{ cursor: 'pointer' }}
                      />
                      <Button
                        className={styles['info-btn']}
                        color="transparent"
                        onClick={async () => {
                          setLoadingHideContactNumber(true);
                          const updatedUser = await handleProfileUpdate(
                            id,
                            'isHideContactNumber',
                            !user?.isHideContactNumber
                          );
                          updateUser(updatedUser);
                          setLoadingHideContactNumber(false);
                        }}
                      >
                        {loadingHideContactNumber ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin={true}
                            size="sm"
                          />
                        ) : user?.isHideContactNumber ? (
                          <img alt="icon" src="/images/eyeCloseIcon.svg" />
                        ) : (
                          <img alt="icon" src="/images/eyeOpenIcon.svg" />
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {!user?.contactNumber && profileId === id && (
                <div className={styles['contact-info']}>
                  <span>
                    <img
                      alt="icon"
                      src="/images/phoneIcon.svg"
                      className={styles['phone-contact-img']}
                      style={{
                        marginRight: '8px',
                      }}
                    />

                    <span
                      style={{
                        borderBottom: '0.5px dotted #000',
                        cursor: 'pointer',
                        fontSize: '12px',
                      }}
                      onClick={() => setContactNumberModal(true)}
                    >
                      + Add Phone Number
                    </span>
                  </span>
                  <div>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      onClick={() => setContactNumberModal(true)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '12px',
                        marginRight: '4px',
                      }}
                    />{' '}
                    <Button
                      className={styles['info-btn']}
                      color="transparent"
                      onClick={async () => {
                        setLoadingHideContactNumber(true);
                        const updatedUser = await handleProfileUpdate(
                          id,
                          'isHideContactNumber',
                          !user?.isHideContactNumber
                        );
                        updateUser(updatedUser);
                        setLoadingHideContactNumber(false);
                      }}
                    >
                      {loadingHideContactNumber ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      ) : user?.isHideContactNumber ? (
                        <img alt="icon" src="/images/eyeCloseIcon.svg" />
                      ) : (
                        <img alt="icon" src="/images/eyeOpenIcon.svg" />
                      )}
                    </Button>
                  </div>
                </div>
              )}
              {user?.emailAddress && (
                <div className={styles['contact-info']}>
                  <span
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <img
                      alt="icon"
                      src="/images/mailIcon.svg"
                      className={styles['phone-contact-img']}
                    />{' '}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={'tooltip-left'}>
                          {user?.emailAddress}
                        </Tooltip>
                      }
                    >
                      <span>{user?.emailAddress}</span>
                    </OverlayTrigger>
                  </span>

                  {profileId === id && (
                    <Button
                      className={styles['info-btn']}
                      color="transparent"
                      onClick={async () => {
                        setLoadingHideEmailAddress(true);
                        const updatedUser = await handleProfileUpdate(
                          id,
                          'isHideEmailAddress',
                          !user?.isHideEmailAddress
                        );
                        updateUser(updatedUser);
                        setLoadingHideEmailAddress(false);
                      }}
                    >
                      {loadingHideEmailAddress ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      ) : user?.isHideEmailAddress ? (
                        <img alt="icon" src="/images/eyeCloseIcon.svg" />
                      ) : (
                        <img alt="icon" src="/images/eyeOpenIcon.svg" />
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}

          <div className={styles['profile-options']}>
            {user?.isProfessional && (
              <Button
                onClick={() => router(`/profile?profileId=${profileId}`)}
                color="transparent"
                className={styles['option-item']}
              >
                <img
                  alt="icon"
                  src={'/images/profileIcon.svg'}
                  className={styles['profile-dropdown-item-img']}
                />
                &nbsp;&nbsp;{id === profileId ? 'My' : ''} Profile
                {!view && (
                  <span className={styles.floatingIcon}>
                    <FontAwesomeIcon
                      size="sm"
                      icon={faDiamond}
                      style={{ fontSize: '10px' }}
                    />
                  </span>
                )}
              </Button>
            )}
            {categoryMap(id === profileId, user?.isProfessional).map(
              (item, index) => {
                return (
                  <Button
                    onClick={() => handleView(item?.key)}
                    color="transparent"
                    className={styles['option-item']}
                    key={index}
                  >
                    <img
                      alt="icon"
                      src={item.icon}
                      className={styles['profile-dropdown-item-img']}
                    />
                    &nbsp;&nbsp;{item.title} &nbsp;
                    {item.key === 'questions'
                      ? `(${user?.postCount ? user?.postCount : 0})`
                      : item.key === 'published-works'
                      ? `(${user?.workCount ? user?.workCount : 0})`
                      : item.key === 'answers'
                      ? `(${
                          (user?.commentCount ?? 0) + (user?.replyCount ?? 0)
                        })`
                      : item.key === 'networks'
                      ? `(${
                          (user?.followerCount ?? 0) +
                          (user?.followingCount ?? 0)
                        })`
                      : ''}
                    {view === item.key && (
                      <span className={styles.floatingIcon}>
                        <FontAwesomeIcon
                          size="sm"
                          icon={faDiamond}
                          style={{ fontSize: '10px' }}
                        />
                      </span>
                    )}
                  </Button>
                );
              }
            )}
          </div>

          {showReportUser && profileId && (
            <Report
              userId={profileId}
              toggle={() => setShowReportUser((prev) => !prev)}
              isOpen={showReportUser}
              onReport={() => setShowReportUser((prev) => !prev)}
            />
          )}
          <UpgradeToProfessional
            show={showUpgradeModal}
            setShow={setShowUpgradeModal}
          />

          <LocationModal
            isOpen={modalOpen}
            toggleModal={toggleModal}
            handleLocationUpdate={handleLocationUpdate}
          />

          <ContactNumberModal
            isOpen={contactNumberModal}
            toggle={() => setContactNumberModal(!contactNumberModal)}
            contactNumber={updateContactNumber}
            setContactNumber={setUpdateContactNumber}
            onUpdate={handleContactNumberUpdate}
            userContactNumber={user?.contactNumber}
          />
          <UserNameModal
            isOpen={userNameModal}
            toggle={() => setUserNameModal(!userNameModal)}
            userName={updateUserName}
            setUserName={setUpdateUserName}
            onUpdate={handleUserNameUpdate}
            currentUserName={user?.name}
          />
        </div>
      </div>
      {window.innerWidth > 481 &&
        window.innerWidth < 1023 &&
        user?.isProfessional === true && (
          <div className={styles['profile-sidebar-parent']}>
            <Services />
          </div>
        )}
    </>
  );
};

export default ProfileSidebar;
