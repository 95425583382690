import React, { useState } from 'react';
import JobDetailsModal from './JobDetailsModal';
import styles from './hiringJobs.module.css';
import { formatDateStr } from 'src/utils/dateUtil';
import {
  faCheck,
  faIndianRupeeSign,
  faLocationDot,
  faMagnifyingGlassChart,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resolveBEAsset } from 'src/utils/urls';
import EventActions from '../events/event-actions';
import { formatToIndianCurrency, SubmittedJobData } from './HiringJobs';
import { useNavigate } from 'react-router-dom';
import SignupModal from '../stepper/SignupModal';

export default function HiringJobsListItem({ job }: any) {
  const loggedInUserId = localStorage.getItem('id') || '';
  const userRole = localStorage.getItem('role');
  const [isCopying, setIsCopying] = useState(false);

  const [signupModal, setSignupModal] = useState(false);
  const token = localStorage.getItem('token') || '';

  const handleCopyJob = (job: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(
      `https://community.whitecollarprofessional.com/jobs?jobId=${job?._id}`
    );
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };
  const navigate = useNavigate();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<SubmittedJobData | null>(null);

  const openDetailsModal = (job: SubmittedJobData) => {
    setSelectedJob(job);
    setDetailsModalOpen(true);
  };
  const closeDetailsModal = () => {
    setSelectedJob(null);
    setDetailsModalOpen(false);
  };

  return (
    <>
      <div key={job._id} className={styles['jobs-wrapper']}>
        <div className={styles['jobs-title-body-wrapper']}>
          <div className={styles['jobs-title-wrapper']}>
            <img
              src={
                job?.jobImage
                  ? resolveBEAsset(job?.jobImage)
                  : '/images/eventsDefaultImage.png'
              }
              alt="job logo - Team WCP"
            />

            <div className={styles['job-company-name']}>{job.jobName}</div>
            <>
              <div style={{ margin: '0 10px 0 auto' }}>
                {!isCopying ? (
                  <FontAwesomeIcon
                    icon={faCopy}
                    onClick={() => handleCopyJob(job)}
                    style={{
                      cursor: 'pointer',
                      fontSize: '18px',
                      color: '#666',
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles['check-icon']}
                  />
                )}
              </div>
              {isCopying && window.innerWidth <= 700 && (
                <div
                  style={{
                    position: 'fixed',
                    bottom: '110px',
                    width: '100%',
                    margin: '0 auto',
                    paddingRight: '48px',
                    zIndex: '999',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      padding: '4px 8px',
                      border: '1px solid #ccc',
                      backgroundColor: '#e6e6e6',
                      width: 'max-content',
                      color: '#1a1a1a',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    Copied to Clipboard
                  </div>
                </div>
              )}
              {(userRole === 'SUPER_ADMIN' ||
                job?._userId === loggedInUserId) && (
                <div>
                  <EventActions postId={job?._id} type={'job'} />
                </div>
              )}
            </>
          </div>
          <div className={styles['jobs-body-wrapper']}>
            <div className={styles['jobs-sub-body-wrapper']}>
              <div>
                <div className={styles['jobs-image-wrapper']}>
                  <img
                    src="/images/certificate-file.svg"
                    alt="certifiedFile-icon"
                    height={16}
                    style={{ marginLeft: '-3px' }}
                  />
                </div>
                <div className={styles['jobs-image-wrapper']}>
                  <img
                    src="/images/users-roles.svg"
                    alt="certifiedFile-icon"
                    height={16}
                    width={30}
                    style={{ marginLeft: '-10px' }}
                  />
                </div>
                <div className={styles['jobs-image-wrapper']}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} color="#666666" />
                </div>
              </div>
              <div className={styles['jobs-value-wrapper']}>
                <div>{job.jobQualification ? job.jobQualification : 'N/A'}</div>
                <div>{job.jobExperience ? job.jobExperience : 'N/A'}</div>
                <div>
                  {job.jobAmount
                    ? formatToIndianCurrency(job.jobAmount)
                    : 'Not Disclosed'}
                </div>
              </div>
            </div>
            <div className={styles['jobs-sub-body-wrapper']}>
              <div>
                <div>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassChart}
                    color="#666666"
                  />
                </div>
                <div>
                  <FontAwesomeIcon icon={faLocationDot} color="#666666" />
                </div>
                <div>
                  <img
                    src="/images/calender-exclamation.svg"
                    alt="certifiedFile-icon"
                    width={16}
                    style={{ marginLeft: '-2px' }}
                  />
                </div>
              </div>
              <div className={styles['jobs-value-wrapper']}>
                <div>{job.jobOpening}</div>
                <div>
                  {job?.jobLocation.includes(',')
                    ? 'Multiple Locations'
                    : job?.jobLocation}
                </div>

                <div>{formatDateStr(job.jobDeadLine)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['jobs-footer-wrapper']}>
          <div className={styles['jobs-footer-postedBy']}>
            Posted By:{' '}
            <i
              className={styles['job-post-userName']}
              onClick={() => {
                !token
                  ? setSignupModal(true)
                  : navigate(`/profile?profileId=${job?._userId}`);
              }}
            >
              {job?.userName}
            </i>
          </div>

          <div
            className={styles['jobs-footer-showMore']}
            onClick={() =>
              !token ? setSignupModal(true) : openDetailsModal(job)
            }
          >
            Show More
          </div>
        </div>
      </div>
      {detailsModalOpen && selectedJob && (
        <JobDetailsModal
          isOpen={detailsModalOpen}
          onClose={closeDetailsModal}
          job={selectedJob}
        />
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
}
