import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import styles from './eventActions.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  deleteEvent,
  fecthEventReport,
} from 'src/services/event-service/event-service';
import { deleteJob } from 'src/services/job-service/job-service';

import InterestedUserModal from './interetedUserModal';

type Props = {
  postId: string;
  type: string;
};

export default function EventActions({ postId, type }: Props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const router = useNavigate();

  const handleClick = async () => {
    if (type === 'job') {
      await deleteJob(postId);
    } else {
      await deleteEvent(postId);
    }
    router(0);
  };
  const [interstedUsers, setInterestedUsers] = useState([]);
  const getEventReport = async () => {
    try {
      const interestedEventUsers = await fecthEventReport(postId);
      setInterestedUsers(interestedEventUsers.data || []);
      setShowInterestedUsers(true);
    } catch (error) {
      console.log('error in fetching Event report', error);
    }
  };
  const [showInterestedUsers, setShowInterestedUsers] = useState(false);

  return (
    <>
      <Dropdown
        className={styles.menuButton}
        isOpen={showMenu}
        toggle={() => setShowMenu((old) => !old)}
        direction="down"
      >
        <DropdownToggle>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </DropdownToggle>
        <DropdownMenu>
          {type !== 'job' && (
            <DropdownItem>
              <div onClick={() => getEventReport()}>Event Report</div>
            </DropdownItem>
          )}
          <DropdownItem>
            <div onClick={() => handleClick()}>Delete</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {showInterestedUsers && (
        <InterestedUserModal
          interstedUsers={interstedUsers}
          showInterestedUsers={showInterestedUsers}
          setShowInterestedUsers={setShowInterestedUsers}
        />
      )}
    </>
  );
}
