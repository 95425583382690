import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import districtData from 'src/geographicalDetails/district';
import predefinedStates from 'src/geographicalDetails/state';
import { StylesConfig } from 'react-select';
import styles from './userUpdateModal.module.css';

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '1.5rem',
    width: '250px',
    border: '1px solid #1a1a1a',
    fontWeight: 'bold',
    color: 'black',
    height: '1rem',
    fontSize: '14px',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    height: '1rem',
    margin: '0 0.25rem 3px 0.25rem',
    padding: 0,
    fontSize: '14px',
  }),
};
const mobileStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '24px',
    padding: '0 8px',
    border: '1px solid #1a1a1a',
    fontWeight: 'bold',
    color: 'black',
    width: '240px',
    minHeight: '20px',
    height: 'max-content',
    fontSize: '14px',

    '&:hover': {
      border: '1px solid #1a1a1a',
      fontWeight: 'bold',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,

    margin: '2px 8px',
    height: 'max-content',
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    width: 'max-content',
    height: 'max-content',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 ',
    div: { padding: '4px 3.5px' },
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '8px',
    marginRight: 0,
    fontSize: '14px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    svg: { height: '11px', width: '11px' },
    color: 'black',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};
interface LocationModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  handleLocationUpdate: (state: string, city: string) => void;
}

const LocationModal: React.FC<LocationModalProps> = ({
  isOpen,
  toggleModal,
  handleLocationUpdate,
}) => {
  const [selectedState, setSelectedState] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedCity, setSelectedCity] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const stateOptions = useMemo(
    () => predefinedStates.map((state) => ({ label: state, value: state })),
    []
  );

  const fetchCities = useCallback(
    (inputValue: any) => {
      if (!selectedState) return Promise.resolve([]);
      const cities = districtData[selectedState.value] || [];
      return Promise.resolve(
        cities
          .filter((city) =>
            city.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((city) => ({ label: city, value: city }))
      );
    },
    [selectedState]
  );

  const handleStateChange = (selectedOption: any) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption: any) => {
    setSelectedCity(selectedOption);
  };

  const onSubmit = () => {
    if (selectedState && selectedCity) {
      handleLocationUpdate(selectedState.value, selectedCity.value);
      toggleModal();
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      size={window.innerWidth > 700 ? 'md' : 'sm'}
      centered={isMobile}
      className={styles['location-update-modal']}
    >
      <ModalHeader
        toggle={toggleModal}
        className={styles['location-modal-header']}
      >
        Update Location
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Select
            options={stateOptions}
            onChange={handleStateChange}
            value={selectedState}
            placeholder="Select State"
            styles={window.innerWidth < 700 ? mobileStyles : customStyles}
            isClearable={true}
          />
          <AsyncSelect
            key={selectedState ? selectedState.value : 'none'}
            loadOptions={fetchCities}
            onChange={handleCityChange}
            value={selectedCity}
            placeholder="Select City"
            isDisabled={!selectedState} // Disable city selector when no state is selected
            defaultOptions
            cacheOptions
            styles={window.innerWidth < 700 ? mobileStyles : customStyles}
            isClearable={true}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSubmit} className={styles['user-location-submit']}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LocationModal;
