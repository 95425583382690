import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Popover, PopoverBody } from 'reactstrap';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUsersbyMatch } from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { ValidationMessage } from '../validation-message';
import styles from './request.module.css';
import { UserList } from './user-list';

type Props = {
  onRequest: (users: Array<IUser>) => void;
  onReset: () => void;
  label: string;
};

export const Request = ({ onRequest, onReset, label }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [selectedUsers, setSelectedUser] = useState<Array<IUser>>([]);
  const [noResult, setNoResult] = useState(false);
  const buttonRef = useRef(null);

  const handleDebounceFn = async (search: string) => {
    setLoading(true);
    const { data } = await fetchUsersbyMatch(search, {
      populate: 'memberships',
    });
    if (!data?.length) {
      setNoResult(true);
    }
    setUsers(data);
    setLoading(false);
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleChange = (event: any) => {
    setNoResult(false);
    const search = event.target?.value || '';
    setValue(search);
    if (search.length > 2) {
      debounceFn(event.target?.value || '');
    } else {
      setUsers([]);
    }
  };

  useEffect(() => {
    setSelectedUser([]);
    setUsers([]);
    setValue('');
  }, [isOpen]);
  const token = localStorage.getItem('token');

  return (
    <>
      <div className={styles.filesContainer} ref={buttonRef}>
        <Button
          className={styles['interaction-btn']}
          onClick={() => setIsOpen(true)}
          color="transparent"
          disabled={!token}
        >
          <img src="/images/requestIcon.svg" alt="" />

          {label}
        </Button>
      </div>
      {isOpen && (
        <Popover
          className={styles.requestPopupContainer}
          isOpen={isOpen}
          placement="bottom-start"
          target={buttonRef.current!}
          trigger="legacy"
          toggle={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        >
          <PopoverBody>
            <Input
              type="text"
              className={styles['request-search-input']}
              placeholder="Search by name"
              value={value}
              onChange={handleChange}
            />
            {value.length > 0 && value.length < 3 && (
              <ValidationMessage>
                Enter at least 3 letters to search.
              </ValidationMessage>
            )}
            {!loading && noResult && (
              <ValidationMessage>No user found.</ValidationMessage>
            )}
            {loading ? (
              <LoadingLottie height="200px" />
            ) : (
              <div className={styles.userScrollContainer}>
                <UserList
                  onChange={(users) => setSelectedUser(users)}
                  selectedUsers={selectedUsers}
                  users={users}
                />
              </div>
            )}
            {selectedUsers?.length > 0 && (
              <div className={styles.applyContainer}>
                <div className={styles.reset}>
                  <Button
                    className={styles.roundButton}
                    onClick={() => {
                      setSelectedUser([]);
                      onReset();
                    }}
                  >
                    Reset
                  </Button>
                </div>
                <div className={styles.request}>
                  <Button
                    className={styles.roundButton}
                    onClick={() => {
                      onRequest(selectedUsers);
                      setSelectedUser([]);
                      setIsOpen(false);
                    }}
                  >
                    Request
                  </Button>
                </div>
              </div>
            )}
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};
