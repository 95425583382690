import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styles from './newsignup.module.css';

import { LoadingLottie } from 'src/components/lotties/loading';
import { SuccessLottie } from 'src/components/lotties/success';
import { ValidationMessage } from 'src/components/validation-message';
import { checkIfRequiredFieldsEntered, validateForm } from './NewSignUp.helper';
import {
  updateUser,
  uploadUserAttachment,
  sendOtpSignupNonProf,
  createOtherUser,
} from 'src/services/user-service/user-servicec';
import AppPopUp from 'src/components/bottombar/AppPopUp';
import axiosInstance from 'src/config/api';
import LoginBtns from 'src/components/login-btns';

function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  // eslint-disable-next-line no-undef
  gtag('event', 'conversion', {
    send_to: 'AW-11329981835/TVwiCKmDh6IZEIubx5oq',
    event_callback: callback,
  });
  return false;
}

function NewSignupForm() {
  const router = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      router('/dashboard');
    }
  }, []);

  const navigateToDashboard = () => {
    router('/dashboard');
  };

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMess, setErrorMess] = useState({});

  const [showSuccess, setShowSuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const baseUrl = process.env.REACT_APP_API_HOST;
  const [otp, setOtp] = useState('');
  const [otpInputVisible, setOtpInputVisible] = useState(false);
  const [getOtpButtonDisabled, setGetOtpButtonDisabled] = useState(false);
  const [resendOtpButtonVisible, setResendOtpButtonVisible] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [verifyOtpError, setVerifyOtpError] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: files ? files[0] : value });

    setErrorMess((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const formDataWithLowercasedEmail = {
      ...formData,
      email: formData.email.toLowerCase(),
    };

    setErrorMess({});

    const errors = validateForm(formDataWithLowercasedEmail);

    if (Object.keys(errors).length === 0 && isEmailVerified) {
      const res = await createOtherUser(formDataWithLowercasedEmail);

      const profilePic = new FormData();
      profilePic.append('file', formData.profilePicture);
      const savedPic = await uploadUserAttachment(profilePic, res.token);

      const updatedResponse = await updateUser(
        res.id,
        { picture: savedPic },
        res.token
      );

      localStorage.setItem(
        'user',
        JSON.stringify({ ...res, ...updatedResponse })
      );
      localStorage.setItem('id', res._id);
      localStorage.setItem('token', res.token);
      localStorage.setItem('role', res.role);
      gtag_report_conversion();

      setLoading(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigateToDashboard();
      }, 1500);
    } else {
      setErrorMess(errors);
      setLoading(false);
    }
  };

  const handleGetOtp = async () => {
    try {
      await sendOtpSignupNonProf(formData.email);
      setOtpInputVisible(true);
      setGetOtpButtonDisabled(true);
      setResendOtpButtonVisible(false);

      setTimeout(() => {
        setGetOtpButtonDisabled(false);
        setResendOtpButtonVisible(true);
      }, 30000);
      setOtpError(false);
    } catch (error) {
      setOtpError(true);
      setOtpErrorMessage(error.message);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/api/v1/otp/verify`,
        {
          emailAddress: formData.email,
          otp: otp,
        }
      );
      setIsEmailVerified(true);
      setVerifyOtpError(false);
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setVerifyOtpError(true);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  const [apploader, setApploader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setApploader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  if (apploader) {
    return (
      <div>
        <div className={styles['preloader-plus']}>
          <img
            src="/images/wc_logoMain.svg"
            className={styles['preloader-custom-img']}
            alt="wcp-logo"
          />
        </div>
      </div>
    );
  } else
    return (
      <>
        {' '}
        <div className={styles['new-signup-navbar']}>
          <Button color="transparent" onClick={() => navigateToDashboard()}>
            Home
          </Button>

          <Button color="transparent">
            <LoginBtns
              signin="Sign In"
              textDecoration
              color
              fontSize={isMobile ? '16px' : '20px'}
              fontWeight
            />
          </Button>
        </div>
        <div className={styles['new-signup-parent']}>
          <div className={styles['new-signup-container']}>
            <>
              {loading ? (
                <div>
                  <LoadingLottie height={410} />
                </div>
              ) : showSuccess ? (
                <SuccessLottie height={410} />
              ) : (
                <>
                  <div className={styles['brand-logo']}>
                    <img src="/images/logo.png" alt="hi" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <img
                      className={styles['signupGoogle-image']}
                      src="/images/signupGoogle.png"
                      alt="googleSigninLogo"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_HOST}/api/v1/auth/google`,
                          '_self'
                        )
                      }
                    />
                  </div>{' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: 'rgb(115, 115, 115, 1)',
                      margin: '30px auto 20px auto',
                      width: '75%',
                    }}
                  >
                    <div
                      style={{
                        flexGrow: '1',
                        height: '1px',
                        backgroundColor: 'rgb(219, 219, 219)',
                      }}
                    ></div>
                    <div
                      style={{
                        padding: '0 10px',
                        fontSize: '10px',
                        fontFamily: 'Roboto',
                      }}
                    >
                      OR
                    </div>
                    <div
                      style={{
                        flexGrow: '1',
                        height: '1px',
                        backgroundColor: 'rgb(219, 219, 219)',
                      }}
                    ></div>
                  </div>
                  <Form
                    onSubmit={handleSubmit}
                    noValidate
                    className={styles['new-signup-form-container']}
                  >
                    <div className={styles['new-signup-form']}>
                      <Form.Group controlId="name">
                        <Form.Label className="mt-2">
                          <b>
                            Name{' '}
                            <span style={{ color: 'red' }}>
                              {!formData.name ? '*' : ''}
                            </span>
                          </b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter your Name..."
                          value={formData.name}
                          onChange={handleChange}
                          isInvalid={!!errorMess.name}
                          required
                        />

                        <Form.Control.Feedback type="invalid">
                          <ValidationMessage>
                            This field is required.
                          </ValidationMessage>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Label
                          className={window.innerWidth > 700 ? 'mt-4' : 'mt-3'}
                        >
                          <b>
                            Email{' '}
                            <span style={{ color: 'red' }}>
                              {!formData.email ? '*' : ''}
                            </span>
                          </b>
                        </Form.Label>
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <Form.Control
                            type="email"
                            placeholder="Enter your Email-Id..."
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!errorMess.email}
                            required
                          />
                          {otpError && (
                            <ValidationMessage>
                              {otpErrorMessage}
                            </ValidationMessage>
                          )}
                          {isEmailVerified && (
                            <img
                              src="/images/prof-verification.png"
                              alt="isVerified"
                              className={styles['verifiedOtpTickLogo']}
                            />
                          )}
                        </div>
                        {formData.email && !isEmailVerified && (
                          <div className={styles['otpWrapper']}>
                            <>
                              <Form.Control
                                type="text"
                                placeholder="Enter your OTP..."
                                name="Otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className={styles['otp-input']}
                              />
                              {otpInputVisible && (
                                <Button
                                  className={styles['otpButton']}
                                  onClick={handleVerifyOtp}
                                  variant="secondary"
                                >
                                  Verify OTP
                                </Button>
                              )}
                            </>

                            <div>
                              <Button
                                variant="secondary"
                                onClick={handleGetOtp}
                                disabled={getOtpButtonDisabled}
                                className={styles['otpButton']}
                              >
                                {resendOtpButtonVisible || getOtpButtonDisabled
                                  ? 'Resend OTP'
                                  : 'Get OTP'}
                              </Button>
                            </div>
                          </div>
                        )}
                        {verifyOtpError && (
                          <div style={{ marginTop: '3px' }}>
                            <ValidationMessage>Invalid OTP</ValidationMessage>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          <ValidationMessage>
                            This field is required.
                          </ValidationMessage>
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId="password"
                        className={styles['form-password-container']}
                      >
                        <Form.Label
                          className={window.innerWidth > 700 ? 'mt-4' : 'mt-3'}
                        >
                          <b>
                            Password{' '}
                            <span style={{ color: 'red' }}>
                              {!formData.password ? '*' : ''}
                            </span>
                          </b>
                        </Form.Label>
                        <div className={styles['password-input-container']}>
                          <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={formData.password}
                            placeholder="Enter your Password..."
                            onChange={handleChange}
                            isInvalid={!!errorMess.password}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            <ValidationMessage>
                              This field is required.
                            </ValidationMessage>
                          </Form.Control.Feedback>
                          <Button
                            color="transparent"
                            onClick={togglePasswordVisibility}
                            className={
                              errorMess.password
                                ? `${styles['pass-eyebtn-error']}`
                                : `${styles['pass-eyebtn']}`
                            }
                          >
                            {showPassword ? (
                              <img alt="icon" src="/images/eyeOpenIcon.svg" />
                            ) : (
                              <img alt="icon" src="/images/eyeCloseIcon.svg" />
                            )}
                          </Button>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="checkBox">
                        <div className={styles['tnc-container']}>
                          <p>
                            By signing up, I confirm that I have read, consent
                            to, and agree to White Collar Professional's{' '}
                            <a
                              href="https://www.whitecollarprofessional.com/legal-policies-wcp_community/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Legal Policy
                            </a>{' '}
                            .
                          </p>
                        </div>
                      </Form.Group>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant="secondary"
                        className={styles['form-submit']}
                        type="submit"
                        disabled={checkIfRequiredFieldsEntered(
                          formData,
                          isEmailVerified
                        )}
                      >
                        {<span className="font-bold-text"> Submit </span>}
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </>
          </div>
        </div>
        <div className={styles['new-signup-footer']}>
          {isMobile
            ? `Copyright © 2024 - Taaran Industries Private Limited`
            : `Copyright © 2024 White Collar Professional - Taaran Industries Private Limited`}
        </div>
        {isMobile && <AppPopUp bottom="0" />}
      </>
    );
}

export default NewSignupForm;
