import axiosInstance from 'src/config/api';
import {
  IAttachment,
  IComment,
  IEventResponse,
  IReply,
} from '../post-service/post-service.interface';

export const fetchEvents = async (
  pageNumber: number,
  baseStartDate?: string,
  baseEndDate?: string,
  eventId?: string,
  eventNameSearch?: string
): Promise<IEventResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber,
      pageSize: 10,
      sortBy: 'startedOn',
    };

    if (eventId) {
      params._id = eventId;
    }
    if (eventNameSearch) {
      params.eventNameSearch = eventNameSearch;
    }

    if (!eventId && !eventNameSearch) {
      if (baseStartDate) {
        params.startedOnFrom = baseStartDate;
        params.sortOrder = 'asc';
      }
      if (baseEndDate) {
        params.startedOnTo = baseEndDate;
        params.sortOrder = 'desc';
      }
    }

    const response = await axiosInstance.get(`${baseUrl}/api/v1/event`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while fetching events', error);
  }
  //@ts-ignore
  return;
};

export const fetchEventSearch = async (
  eventNameSearch?: string
): Promise<IEventResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber: 1,
      pageSize: 15,
      sortBy: 'startedOn',
    };

    if (eventNameSearch) {
      params.eventNameSearch = eventNameSearch;
    }

    const response = await axiosInstance.get(`${baseUrl}/api/v1/event`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  //@ts-ignore
  return;
};
export const createEvent = async (
  attachments: IAttachment[],
  title: string,
  startedOn: string,
  endedOn: string,
  link: string,
  content: string,
  cpeCredit: string,
  eventType: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/event`, {
      attachments,
      title,
      startedOn,
      endedOn,
      link,
      content,
      cpeCredit,
      eventType,
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  return;
};

export const deleteEvent = async (eventId: string): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.delete(`${baseUrl}/api/v1/event/${eventId}`))
    .data;
};

export const eventUpvote = async (eventId: string): Promise<IReply> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.post(`${baseUrl}/api/v1/event/${eventId}/upvote`))
    .data;
};

export const deleteEventUpvote = async (
  upvoteId: string
): Promise<IComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(`${baseUrl}/api/v1/event/upvote/${upvoteId}`)
  ).data;
};

export const fetchEventUpvotes = async (
  _userId: string,
  upvoteType: string,
  ids: string[]
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  let upvoteStr = '';
  ids?.forEach((id) => {
    upvoteStr = upvoteStr + `&${upvoteType}=${id}`;
  });

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/event/upvote?_userId=${_userId}${upvoteStr}`
    )
  ).data;
};

export const fetchAllEventUpvotes = async (eventIds: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(`${baseUrl}/api/v1/event/allevent/upvotes`, {
    eventIds,
  });
};

//fetch event report
export const fecthEventReport = async (eventId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;
  return (
    await axiosInstance.get(`${baseUrl}/api/v1/event/${eventId}/event-report`)
  ).data;
};
