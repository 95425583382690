import { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { UserContext } from 'src/context/user.context';
import styles from './avatar-row.module.css';
import { useNavigate } from 'react-router';
import {
  IExperience,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  deleteFollowUser,
  followUser,
} from 'src/services/user-service/user-servicec';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resolveBEAsset } from 'src/utils/urls';
import SignupModal from '../stepper/SignupModal';

type Props = {
  data: IUser;
  showLocation?: boolean;
  showMembership?: boolean;
  position?: string;
};

export default function AvatarRow({
  data,
  showLocation,
  showMembership,
  position,
}: Props) {
  const router = useNavigate();

  const [loadingFollowing, setLoadingFollowing] = useState(false);

  const { following, id, addFollowing, removeFollowing } =
    useContext(UserContext);

  const handleFollow = async (): Promise<void> => {
    setLoadingFollowing(true);
    const { data: newFollowing } = await followUser(id, data._id);
    addFollowing(newFollowing);
    setLoadingFollowing(false);
  };

  const handleUnFollow = async (followingId: string): Promise<void> => {
    setLoadingFollowing(true);
    const { data: newFollowing } = await deleteFollowUser(id, followingId);
    removeFollowing(newFollowing);
    setLoadingFollowing(false);
  };

  const userFollowings = following?.filter(({ type }: any) => type === 'USER');
  const followingId = userFollowings.find(
    (follow: any) => follow.userId === data?._id
  )?._id;
  const self = data?._id === id;
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);

  const professionFullMap: { [key: string]: string } = {
    ICAI: 'Chartered Accountant',
    ICSI: 'Company Secretary',
    ICMAI: 'Cost & Work Accountant',
    BCI: 'Advocate',
  };
  const professionMap: { [key: string]: string } = {
    ICAI: 'CA',
    ICSI: 'CS',
    ICMAI: 'CWA',
    BCI: 'Adv.',
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //getting latest expewrience:
  const displayedExperience =
    data?.experiences
      ?.sort(
        (a: IExperience, b: IExperience) =>
          new Date(b.startedOn).getTime() - new Date(a.startedOn).getTime()
      )
      .find((experience: IExperience) => !experience?.endedOn) ||
    data?.experiences?.sort(
      (a: IExperience, b: IExperience) =>
        new Date(b.startedOn).getTime() - new Date(a.startedOn).getTime()
    )[0];

  return (
    <>
      <div className={styles['item-avatar']}>
        {showMembership || showLocation ? (
          <img
            src={
              data?.picture?.path
                ? resolveBEAsset(data?.picture?.path || '')
                : '/images/defaultProfilePic.svg'
            }
            onClick={() => {
              !token
                ? setSignupModal(true)
                : router(`/profile?profileId=${data?._id}`);
            }}
            alt="user profile"
            className={styles['avatar-img-big']}
            width={0}
            height={0}
          />
        ) : (
          <img
            src={
              data?.picture?.path
                ? resolveBEAsset(data?.picture?.path || '')
                : '/images/defaultProfilePic.svg'
            }
            onClick={() => {
              !token
                ? setSignupModal(true)
                : router(`/profile?profileId=${data?._id}`);
            }}
            alt="user profile"
            className={styles['avatar-img']}
            width={0}
            height={0}
          />
        )}
        <p
          className={styles['user-name']}
          onClick={() => {
            !token
              ? setSignupModal(true)
              : router(`/profile?profileId=${data?._id}`);
          }}
        >
          {showMembership ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isMobile
                ? data?.name?.length > 18
                  ? data?.name?.slice(0, 16) + '..'
                  : data?.name
                : data?.name}
              &nbsp;
              {data?.isProfessional && (
                <img
                  src="/images/prof-verification.png"
                  alt="verifiedLogo"
                  className={styles['request-verifie-logo']}
                />
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data?.name.length > 25
                ? data?.name.slice(0, 25) + '..'
                : data?.name}
              &nbsp;
              {data?.isProfessional && (
                <img
                  src="/images/prof-verification.png"
                  alt="verifiedLogo"
                  className={styles['request-verifie-logo']}
                />
              )}
            </div>
          )}
          {showLocation && data?.city && (
            <div className={styles.city}>{data.city}</div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {' '}
            {showMembership &&
              data?.member?.length > 0 &&
              data.member.map((memberItem, idx) => (
                <button
                  key={idx}
                  style={{
                    color: 'black',
                    fontSize: '10px',
                    borderRadius: '5px',
                    border: 'none',
                    padding: '2px 4px',
                    lineHeight: 'normal',
                    marginRight: '3px',
                  }}
                  className={styles[`color-${memberItem}`]}
                >
                  {professionMap[memberItem]}
                </button>
              ))}
            {position && window.innerWidth > 700 && (
              <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                - {position}
              </span>
            )}
            {position && window.innerWidth < 700 && (
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {' '}
                <span className={styles['bulletPoint']}></span>
                {position}
              </span>
            )}
            {showMembership && displayedExperience && (
              <div className={styles.organizationWrapper}>
                {data?.isProfessional === false ? (
                  <>
                    <FontAwesomeIcon icon={faBuilding} size="sm" />
                  </>
                ) : (
                  <span className={styles.organizationBulletPoint} />
                )}

                <span className={styles.ogranizationName}>
                  {displayedExperience?.name}
                </span>
              </div>
            )}
          </div>
        </p>

        {!self && !showLocation && !showMembership && data?.isProfessional && (
          <div className={styles['follow-btn-wrapper']}>
            {followingId ? (
              <Button
                color="transparent"
                className={styles['follow-btn']}
                onClick={() => handleUnFollow((followingId || '').toString())}
                disabled={!token}
              >
                <img src="/images/followIcon.svg" alt="followImg" />
                <div className={styles['follow-btn-text']}>Following&nbsp;</div>
                {loadingFollowing && (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                )}
              </Button>
            ) : (
              <Button
                color="transparent"
                className={styles['follow-btn']}
                onClick={() => handleFollow()}
                disabled={!token}
              >
                <img src="/images/followIcon.svg" alt="followImg" />
                <div className={styles['follow-btn-text']}>Follow&nbsp;</div>
                {loadingFollowing && (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                )}
              </Button>
            )}
          </div>
        )}
      </div>

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
}
