import { useNavigate } from 'react-router-dom';
import styles from './technicalSupport.module.css';
import { useState } from 'react';
import { Input, Button } from 'reactstrap';

import LoginBtns from '../login-btns';
import { Helmet } from 'react-helmet-async';
import axiosInstance from 'src/config/api';
import { SuccessLottie } from '../lotties/success';
import { LoadingLottie } from '../lotties/loading';
import { FailureLottie } from '../lotties/failure';
import { Modal, ModalBody } from 'react-bootstrap';

const baseUrl = process.env.REACT_APP_API_HOST;
const TechnicalSupport = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNo: '',
    desc: '',
  });
  const [showSucessDetails, setShowSuccessDetails] = useState(false);
  const sendTechSupportMail = async () => {
    setLoading(true);
    try {
      await axiosInstance.post(`${baseUrl}/api/v1/support`, formData);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setShowSuccessDetails(true);
      }, 2500);
    } catch (error) {
      console.log('Error in sending Technical Support Mail', error);

      setLoading(false);

      setFailed(true);
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    }
  };
  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: files ? files[0] : value });
  };
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>White Collar Professional | Technical Support</title>
        <meta
          name="description"
          content="Get expert assistance at White Collar Professional. Visit our Technical Support page for quick resolutions to your queries and seamless platform experience."
        />

        <link rel="canonical" href="/technicalsupport" />
      </Helmet>
      <div className={styles['tech-parent-wrapper']}>
        <div className={styles['tech-support-navbar']}>
          {window?.innerWidth < 700 ? (
            <Button onClick={() => navigate('/')}>Home</Button>
          ) : (
            <img
              src="/images/logo.svg"
              alt="White Collar Professional Logo"
              className={styles['hedaer-logo']}
              onClick={() => navigate('/')}
            />
          )}

          <Button>
            <LoginBtns
              textDecoration
              color
              signin="Sign In"
              fontSize
              fontWeight
            />
          </Button>
        </div>

        <div className={styles['main-container']}>
          {success && <SuccessLottie height={410} />}
          {loading && <LoadingLottie height={410} />}
          {failed && <FailureLottie height={210} />}

          {!success && !loading && !failed && (
            <>
              <div className={styles['border-container']}>
                <div className={styles['subtitle-container']}>
                  <div className={styles['underline']}>Technical Support</div>
                </div>
                {showSucessDetails ? (
                  <div className={styles['success-message']}>
                    <p>
                      Your technical support request has been recorded with us.
                      <i>"{formData.desc}"</i>
                    </p>
                    <Button
                      className={styles['okay-button']}
                      onClick={() => {
                        setFormData({
                          name: '',
                          email: '',
                          contactNo: '',
                          desc: '',
                        });
                        setShowSuccessDetails(false);
                      }}
                    >
                      Okay
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className={styles['tech-input-container']}>
                      <div className={styles['tech-input-field']}>
                        <div className={styles['tech-label']}>Full Name</div>
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.name}
                          name="name"
                        />
                      </div>
                      <div className={styles['tech-input-field']}>
                        <div className={styles['tech-label']}>Contact No.</div>
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.contactNo}
                          name="contactNo"
                        />
                      </div>
                      <div className={styles['tech-input-field']}>
                        <div className={styles['tech-label']}>Email Id</div>
                        <input
                          type="email"
                          onChange={handleChange}
                          value={formData.email}
                          name="email"
                        />
                      </div>
                      <div className={styles['tech-input-field']}>
                        <div className={styles['tech-label']}>Description</div>

                        <Input
                          type="textarea"
                          onChange={handleChange}
                          name="desc"
                          className={styles['tech-input-textarea']}
                          value={formData.desc}
                        />
                      </div>
                    </div>
                    <Button
                      className={styles['submit-button']}
                      onClick={sendTechSupportMail}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles['copyright-footer']}>
          <div className={styles['copyright-mobile']}>
            Copyright © 2024 - Taaran Industries Private Limited
          </div>
          <div className={styles['copyright-window']}>
            Copyright © 2024 White Collar Professional - Taaran Industries
            Private Limited
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicalSupport;
