import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './callback.module.css';
import axiosInstance from 'src/config/api';

export default function Redirect() {
  const [searchParams] = useSearchParams();
  const userId = localStorage.getItem('id') || '';
  const navigate = useNavigate();
  const url = localStorage.getItem('redirectUrl');
  const savedScrollPosition = localStorage.getItem('scrollPosition');
  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      const getToken = async () => {
        try {
          await axiosInstance.post(
            `${
              process.env.NODE_ENV === 'development'
                ? 'http://127.0.0.1:8080'
                : 'https://community.whitecollarprofessional.com'
            }/api/v1/auth/google/redirect`,
            { code, userId }
          );
          if (url) {
            if (url.includes('/events')) {
              const path = new URL(url).pathname;
              navigate(`${path}`);
              if (savedScrollPosition) {
                setTimeout(() => {
                  window.scrollTo(0, parseInt(savedScrollPosition, 10));
                  localStorage.removeItem('scrollPosition');
                  localStorage.removeItem('redirectUrl');
                }, 300);
              }
            } else {
              const path = new URL(url).pathname;
              const currentParams = new URL(url).search;
              navigate(`${path}${currentParams}`);
              if (savedScrollPosition) {
                setTimeout(() => {
                  window.scrollTo(0, parseInt(savedScrollPosition, 10));
                  localStorage.removeItem('scrollPosition');
                  localStorage.removeItem('redirectUrl');
                }, 300);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      };

      getToken();
    }
  }, [searchParams]);

  return (
    <div>
      <div className={styles['preloader-plus']}>
        <img
          src="/images/wc_logoMain.svg"
          className={styles['preloader-custom-img']}
          alt="wcp-logo"
        />
      </div>
    </div>
  );
}
