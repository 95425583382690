import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './imageViewerModal.module.css';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Button } from 'reactstrap';
import { IAttachment } from 'src/services/post-service/post-service.interface';
import { resolveBEAsset } from 'src/utils/urls';

interface ImageViewerModalProps {
  isShowImage: boolean;
  setIsShowImage: (value: boolean) => void;
  currentImage: IAttachment;
  setCurrentimage: (value: IAttachment) => void;
}

export default function ImageViewerModal({
  isShowImage,
  setIsShowImage,
  currentImage,
  setCurrentimage,
}: ImageViewerModalProps) {
  // const getMimeType = (fileName: string): string => {
  //   const extension = fileName.split('.').pop()?.toLowerCase();
  //   const mimeTypes: { [key: string]: string } = {
  //     jpg: 'image/jpeg',
  //     jpeg: 'image/jpeg',
  //     png: 'image/png',
  //     gif: 'image/gif',
  //     webp: 'image/webp',
  //     svg: 'image/svg+xml',
  //     bmp: 'image/bmp',
  //   };

  //   return mimeTypes[extension || ''] || 'application/octet-stream'; // Default to binary file type
  // };

  // const downloadImage = async (imageUrl: string, fileName: string) => {
  //   try {
  //     const mimeType = getMimeType(fileName);

  //     const imageBlob = await fetch(imageUrl)
  //       .then((response) => response.arrayBuffer())
  //       .then((buffer) => new Blob([buffer], { type: mimeType }));

  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(imageBlob);
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading image:', error);
  //   }
  // };

  return (
    <Modal
      show={isShowImage}
      statusBarTranslucent={true}
      animationType="fade"
      transparent={true}
      centered
      onHide={() => {
        setIsShowImage(!isShowImage);
      }}
      className={styles['fullscreen-modal']}
    >
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={resolveBEAsset(currentImage?.path)}
            alt="postImage"
            className={styles['fullscreen-image']}
          />
        </div>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            right: '0',
          }}
        >
          <Button
            onClick={() =>
              downloadImage(
                resolveBEAsset(currentImage?.path),
                currentImage?.originalName
              )
            }
            style={{
              background: '#fff',
              color: '#1a1a1a',
              border: 'none',
              fontSize: `${window?.innerWidth > 700 ? '14px' : '12px'}`,
              margin: '2px 0 40px',
              borderRadius: '0 0 6px 6px',
            }}
          >
            <FontAwesomeIcon icon={faDownload} /> Download
          </Button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}
