import { createBrowserRouter } from 'react-router-dom';
import Dashboard from 'src/pages/dashboard';
import Home from 'src/pages/home';
import Profile from 'src/pages/profile';
import Search from 'src/pages/search';
import WithContext from './with-context';
import Signup from 'src/pages/signup';
import AdminDashboard from 'src/pages/admin-panel';
import TechnicalSupport from 'src/components/technicalSuport/TechnicalSupport';
import PushNotification from 'src/components/PushNotfication/PushNotification';
import BaseSignUp from 'src/components/stepper/BaseSignUp';
import NewSignupForm from 'src/components/stepper/userstep/NewSignUp';
import CreateSignature from 'src/components/createSignature/CreateSignature';
import Events from 'src/components/events/Events';
import Notification from '../notification';
import MessageMobile from '../message/MessageMobile';
import RecentUpdate from 'src/components/recentUpadates/RecentUpdate';
import Callback from '../callback/callback';
import WcpBlog from 'src/components/blogs/WcpBlog';
import AdvocatesPractice from 'src/components/blogs/AdvocatesPractice';
import CaPractice from 'src/components/blogs/CaPractice';
import CsPractice from 'src/components/blogs/CsPractice';
import CwaPractice from 'src/components/blogs/CwaPractice';
import JobSeeker from 'src/components/blogs/JobSeeker';
import StudentPractice from 'src/components/blogs/StudentPractice';
import AppNotification from 'src/components/appNotification/AppNotification';
import HirhingJobs from 'src/components/hiringJobs/HiringJobs';
import Trend from '../trend/trend';
import Layout from 'src/layouts/layout';
import Result from '../question/result';
import Redirect from '../callback/redirect';
import TechSupport from 'src/components/tech-support';
import Reports from 'src/components/reports';
import DailyMailsTrigger from 'src/components/daily-mails';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/callback',
    element: <Callback />,
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '/baseSignUp',
    element: <BaseSignUp />,
  },
  {
    path: '/newsignup',
    element: <NewSignupForm />,
  },

  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/adminPannel',
    element: (
      <WithContext>
        <AdminDashboard />
      </WithContext>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <WithContext>
        <Dashboard />
      </WithContext>
    ),
  },
  {
    path: '/search',
    element: (
      <WithContext>
        <Search />
      </WithContext>
    ),
  },
  {
    path: '/notification',
    element: (
      <WithContext>
        <Notification />
      </WithContext>
    ),
  },
  {
    path: '/profile',
    element: (
      <WithContext>
        <Profile />
      </WithContext>
    ),
  },
  {
    path: '/post',
    element: (
      <WithContext>
        {window.innerWidth < 700 ? (
          <Result profilePage />
        ) : (
          <Layout contentBordered>
            <Result profilePage />
          </Layout>
        )}
      </WithContext>
    ),
  },

  {
    path: '/technicalsupport',
    element: <TechnicalSupport />,
  },
  {
    path: '/events',
    element: (
      <WithContext>
        <Events />
      </WithContext>
    ),
  },
  {
    path: '/push-notification',
    element: (
      <WithContext>
        <PushNotification />
      </WithContext>
    ),
  },
  {
    path: '/create-signature',
    element: (
      <WithContext>
        <CreateSignature />
      </WithContext>
    ),
  },
  {
    path: '/app-notification',
    element: (
      <WithContext>
        <AppNotification />
      </WithContext>
    ),
  },
  {
    path: '/reports',
    element: (
      <WithContext>
        <Reports />
      </WithContext>
    ),
  },
  {
    path: '/email-notifications',
    element: (
      <WithContext>
        <DailyMailsTrigger />
      </WithContext>
    ),
  },
  {
    path: '/tech-support',
    element: (
      <WithContext>
        <TechSupport />
      </WithContext>
    ),
  },
  {
    path: '/message',
    element: (
      <WithContext>
        <MessageMobile />
      </WithContext>
    ),
  },
  {
    path: '/chatwindow',
    element: (
      <WithContext>
        <MessageMobile />
      </WithContext>
    ),
  },
  {
    path: '/update',
    element: (
      <WithContext>
        <RecentUpdate />
      </WithContext>
    ),
  },
  {
    path: '/jobs',
    element: (
      <WithContext>
        <HirhingJobs />
      </WithContext>
    ),
  },
  {
    path: '/blogs',
    element: <WcpBlog />,
  },
  {
    path: '/blogs/advocate-practice',
    element: <AdvocatesPractice />,
  },
  {
    path: '/blogs/ca-practice',
    element: <CaPractice />,
  },
  {
    path: '/blogs/cs-practice',
    element: <CsPractice />,
  },
  {
    path: '/blogs/cwa-practice',
    element: <CwaPractice />,
  },
  {
    path: '/blogs/job-seeker',
    element: <JobSeeker />,
  },
  {
    path: '/blogs/student-practice',
    element: <StudentPractice />,
  },
  {
    path: `/trending`,
    element: (
      <WithContext>
        <Trend />
      </WithContext>
    ),
  },
]);
