import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { postReportPost } from 'src/services/post-service/post-service';
import { postReportUser } from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import { ValidationMessage } from '../validation-message';
import styles from './report.module.css';

type IProps = {
  postId?: string;
  userId?: string;
  isOpen: boolean;
  toggle: () => void;
  onReport: (report: any) => void;
};

export const Report = ({
  postId,
  userId,
  isOpen,
  onReport,
  toggle,
}: IProps) => {
  const [description, setDescription] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetForm = (form: HTMLFormElement) => {
    if (form) {
      form.reset();
    }
    setDescription('');
    setValidated(false);
    setLoading(false);
    setSuccess(false);
  };

  const onReportSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false || !description.length) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        const report = (!postId && userId)
          ? await postReportUser(userId, description)
          : await postReportPost(userId || '', postId!, description);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onReport(report);
          resetForm(form);
        }, 2500);
      } catch (e) {
        resetForm(form);
      }
    }
    setValidated(true);
  };

  const title = postId ? 'Report post' : 'Report user';

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={styles['report-user-modal']}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          {success && <SuccessLottie height={410} />}
          {loading && <LoadingLottie height={410} />}
          {!success && !loading && (
            <Form noValidate validated={validated} onSubmit={onReportSubmit}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  placeholder={'Description'}
                  aria-label={'Description'}
                  value={description}
                  required
                  rows={4}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{ border: '1px solid #E6E6E6', borderRadius: '12px' }}
                  className={styles['report-modal-desc']}
                />
                <Form.Control.Feedback type="invalid">
                  <ValidationMessage>This field is required.</ValidationMessage>
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  className={styles.submitBottom}
                  type="submit"
                >
                  Report
                </Button>
              </div>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
