import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from '../createSignature/createSignature.module.css';

interface CalendarConfirmationModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onNotInterested: () => void;
}

const CalendarConfirmationModal: React.FC<CalendarConfirmationModalProps> = ({
  showModal,
  setShowModal,
  onNotInterested,
}) => {
  const handleSyncCalendar = () => {
    localStorage.setItem('redirectUrl', window.location.href);
    localStorage.setItem('scrollPosition', window.scrollY.toString());

    window.open(
      `${process.env.REACT_APP_API_HOST}/api/v1/auth/google/calendar`,
      '_self'
    );
    setShowModal(false);
  };

  const handleNotInterested = () => {
    onNotInterested();
    setShowModal(false);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className={styles.createSignModal}
    >
      <Modal.Header className={styles['category-modal-header']}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ margin: 'auto' }}
        >
          Manage Your Events with Google
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Integrating your Google Calendar with WCP (White Collar Professional)
          allows us to enhance your scheduling experience.
        </p>
        <br />
        <p>
          By proceeding, you authorize WCP to manage calendar entries for the
          events you select, with full respect for your privacy and preferences.
        </p>
      </Modal.Body>
      <Modal.Footer className={styles.googlePermissionModalFooter}>
        <button className={styles.declineBtn} onClick={handleNotInterested}>
          No, Thank You
        </button>
        <button className={styles.acceptBtn} onClick={handleSyncCalendar}>
          Allow Calendar Access
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarConfirmationModal;
