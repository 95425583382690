import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './scheduleMeeting.module.css';
import { scheduleMeeting } from 'src/services/meetings-service/meetings-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosInstance from 'src/config/api';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import { Button } from 'reactstrap';
import { MessageContext } from 'src/context/message.context';
import {
  convertTo24HourFormat,
  generateNextSevenDays,
  monthMap,
  ScheduleMeetingModalProps,
} from './meetingHepler';
import { SuccessLottie } from '../lotties/success';
import { LoadingLottie } from '../lotties/loading';
import { FailureLottie } from '../lotties/failure';

const ScheduleMeetingModal: React.FC<ScheduleMeetingModalProps> = ({
  onClose,
  isOpen,
  isChat,
  chatUserId,
  onSuccess,
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [timeOptions, setTimeOptions] = useState<
    { time: string; isAvailable: boolean }[]
  >([]);
  const nextSevenDays = generateNextSevenDays();
  const [searchParams] = useSearchParams();
  const [profileUser, setProfileUser] = useState<IUser | null>(null);
  const loggedInUserId = localStorage.getItem('id') || '';
  const profileId = searchParams.get('profileId') || '';
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
  };

  const handleScheduleMeeting = async () => {
    setLoading(true);
    if (!selectedDate || !selectedTime) {
      console.error('Date and Time must be selected to schedule a meeting.');
      return;
    }

    const [hour, minute] = selectedTime.split(':');
    const dateParts = selectedDate.split(' ');

    const month = monthMap[dateParts[1] as keyof typeof monthMap];
    const day = dateParts[2];
    const year = dateParts[3];

    // Create a date object in UTC
    const dateInLocal = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hour, 10),
      parseInt(minute, 10)
    );

    const formattedDateTime = dateInLocal.toISOString();

    const profileId = searchParams.get('profileId') || '';
    const meetingResponse = (await scheduleMeeting(
      title,
      description,
      formattedDateTime,
      chatUserId ? chatUserId : profileId,
      ''
    )) as any;
    const newMeetId = meetingResponse?._id;
    const meetingDetails = {
      title: title,
      description: description,
      startTime: formattedDateTime,
      userId: chatUserId ? chatUserId : profileId,
      meetingId: newMeetId,
    };
    try {
      console.log('the user id is', chatUserId);
      await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}/api/v1/auth/google/schedule-event`,
        { meetingDetails }
      );
      setSelectedDate(null);
      setSelectedTime(null);
      setTitle('');
      setDescription('');
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onSuccess();
      }, 2500);
    } catch (error: any) {
      if (error.response?.data?.details === 'invalid_grant') {
        // Handle re-authentication
        localStorage.setItem('redirectUrl', window.location.href);
        localStorage.setItem('scrollPosition', window.scrollY.toString());
        window.open(
          `${process.env.REACT_APP_API_HOST}/api/v1/auth/google/calendar`,
          '_self'
        );
      } else {
        setLoading(false);
        setFailure(true);
        setTimeout(() => {
          setFailure(false);
          onClose();
        }, 2500);
      }
      console.log('the error is', error);
    }
  };

  const generate24HourTimeSlots = (availability: any[], day: string) => {
    const times: { time: string; isAvailable: boolean }[] = [];
    const outOfRangeSlots: { time: string; isAvailable: boolean }[] = [];

    // Default time slots from 10 AM to 8 PM (20:00)
    for (let i = 10; i <= 20; i++) {
      const hour = i < 10 ? `0${i}` : i;
      times.push({
        time: `${hour}:00`,
        isAvailable: availability.length === 0 ? true : false,
      });
    }

    // Find the availability for the specific day
    const availableDay = availability.find(
      (slot: { day: string }) => slot.day === day
    );

    if (!availableDay) {
      return times; // Return only the default time slots
    }

    const startHour = convertTo24HourFormat(availableDay.startTime);
    const endHour = convertTo24HourFormat(availableDay.endTime);

    // Handle ranges within the default times
    times.forEach((slot) => {
      const hour = parseInt(slot.time.split(':')[0], 10);
      if (
        (startHour <= hour && hour <= endHour) || // Normal case: within start and end
        (startHour > endHour && (hour >= startHour || hour < endHour)) // Cross-midnight case
      ) {
        slot.isAvailable = true;
      }
    });

    // Handle out-of-range slots explicitly
    if (startHour < 10) {
      for (let i = startHour; i <= Math.min(endHour, 10); i++) {
        const hour = i < 10 ? `0${i}` : i;
        outOfRangeSlots.push({ time: `${hour}:00`, isAvailable: true });
      }
    }

    if (endHour > 20) {
      for (let i = Math.max(startHour, 21); i <= endHour; i++) {
        const hour = i < 10 ? `0${i}` : i;
        outOfRangeSlots.push({ time: `${hour}:00`, isAvailable: true });
      }
    }

    // Merge the default and out-of-range slots, ensuring no duplicates
    const allSlots = [...outOfRangeSlots, ...times].sort(
      (a, b) =>
        parseInt(a.time.split(':')[0], 10) - parseInt(b.time.split(':')[0], 10)
    );

    return allSlots;
  };
  const handleDateClick = (date: string) => {
    const dayOfWeek = new Date(date).toLocaleString('en-US', {
      weekday: 'short',
    });
    setSelectedDate((prevDate) => (prevDate === date ? null : date));
    if (profileUser?.availability) {
      setTimeOptions(
        generate24HourTimeSlots(profileUser.availability, dayOfWeek)
      );
    }
  };

  const getUserDetails = async () => {
    try {
      if (chatUserId) {
        const profileUserRes = await fetchUserDetails(chatUserId);
        setProfileUser(profileUserRes);
      } else {
        const profileUserRes = await fetchUserDetails(profileId);
        setProfileUser(profileUserRes);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  const navigate = useNavigate();
  const { fetchMessageFromId } = useContext(MessageContext);
  const handleOpenChatWindow = async () => {
    await fetchMessageFromId(loggedInUserId, profileUser);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {window.innerWidth < 700 || isChat ? (
        <Modal
          show={isOpen}
          onHide={onClose}
          centered
          className={styles.dateSelectorModal}
        >
          <Modal.Body className={styles.dateSelectorModalBody}>
            {success ? (
              <SuccessLottie height={210} />
            ) : failure ? (
              <FailureLottie height={210} />
            ) : loading ? (
              <LoadingLottie height={210} />
            ) : (
              <div className={styles.dateButtonsContainer}>
                {profileUser?.isAvailableForMeetings === false ? (
                  <div className={styles.meetingWindowOffContainer}>
                    <strong>{profileUser?.name.split(' ')[0]}</strong> has
                    turned off his Meeting Window!
                    <br />
                    <br />
                    Kindly, <br />
                    <Button
                      onClick={() => {
                        navigate(`/chatwindow?userid=${profileId}`);
                      }}
                      className={styles.meetingMessageButton}
                    >
                      Message
                    </Button>{' '}
                    <br />
                    to ask them to schedule a new meeting.
                  </div>
                ) : !selectedTime ? (
                  nextSevenDays.map((date) => (
                    <div key={date} className={styles.dateButtonContainer}>
                      <button
                        className={`${styles.dateButton} ${
                          selectedDate === date ? styles.selected : ''
                        }`}
                        onClick={() => handleDateClick(date)}
                      >
                        {date}
                      </button>
                      {selectedDate === date && (
                        <div className={styles.timeButtonsContainer}>
                          {timeOptions.map(({ time, isAvailable }) => (
                            <button
                              key={time}
                              className={`${styles.timeButton} ${
                                !isAvailable ? styles.timeButtonDisabled : ''
                              }`}
                              onClick={() =>
                                isAvailable && handleTimeClick(time)
                              }
                              disabled={!isAvailable}
                            >
                              {time}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    <div className={styles.scheduleForm}>
                      <div>
                        {selectedDate}&nbsp;{selectedTime}
                      </div>
                      <input
                        type="text"
                        placeholder="Meeting Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className={styles.inputField}
                      />
                      <textarea
                        placeholder="Meeting Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className={styles.textareaField}
                      />

                      <button
                        className={styles.scheduleButton}
                        onClick={handleScheduleMeeting}
                      >
                        Schedule Meeting
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <>
          {success ? (
            <SuccessLottie height={210} />
          ) : failure ? (
            <FailureLottie height={210} />
          ) : loading ? (
            <LoadingLottie height={210} />
          ) : (
            <div className={styles.dateButtonsContainer}>
              {profileUser?.isAvailableForMeetings === false ? (
                <div className={styles.meetingWindowOffContainer}>
                  <strong>{profileUser?.name.split(' ')[0]}</strong> has turned
                  off his Meeting Window!
                  <br />
                  <br />
                  Kindly, <br />
                  <Button
                    onClick={() => {
                      handleOpenChatWindow();
                    }}
                    className={styles.meetingMessageButton}
                  >
                    Message
                  </Button>{' '}
                  <br />
                  to ask them to schedule a new meeting.
                </div>
              ) : !selectedTime ? (
                nextSevenDays.map((date) => (
                  <div key={date} className={styles.dateButtonContainer}>
                    <button
                      className={`${styles.dateButton} ${
                        selectedDate === date ? styles.selected : ''
                      }`}
                      onClick={() => handleDateClick(date)}
                    >
                      {date}
                    </button>
                    {selectedDate === date && (
                      <div className={styles.timeButtonsContainer}>
                        {timeOptions.map(({ time, isAvailable }) => (
                          <button
                            key={time}
                            className={`${styles.timeButton} ${
                              !isAvailable ? styles.timeButtonDisabled : ''
                            }`}
                            onClick={() => isAvailable && handleTimeClick(time)}
                            disabled={!isAvailable}
                          >
                            {time}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={styles.scheduleForm}>
                  <div className={styles.selecteddateTime}>
                    {selectedDate}&nbsp;-&nbsp;{selectedTime} IST
                  </div>
                  <input
                    type="text"
                    placeholder="Meeting Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={styles.inputField}
                  />
                  <textarea
                    placeholder="Meeting Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={styles.textareaField}
                  />

                  <button
                    className={styles.scheduleButton}
                    onClick={handleScheduleMeeting}
                  >
                    Schedule Meeting
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ScheduleMeetingModal;
