import React, { useEffect, useState } from 'react';
import { SubmittedJobData } from './HiringJobs';
import { resolveBEAsset } from 'src/utils/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import styles from './hiringJobs.module.css';
import { useNavigate } from 'react-router-dom';
import JobDetailsModal from './JobDetailsModal';
import { fetchJobs } from 'src/services/job-service/job-service';

export default function DashboardJobs() {
  const [jobs, setJobs] = useState<SubmittedJobData[]>([]);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const [selectedJob, setSelectedJob] = useState<SubmittedJobData | null>(null);

  const openDetailsModal = (job: SubmittedJobData) => {
    setSelectedJob(job);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setSelectedJob(null);
    setDetailsModalOpen(false);
  };
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const pageNumber = 1;
      const pageSize = 20;
      const jobData = await fetchJobs(pageNumber, pageSize);
      const formattedJobData: SubmittedJobData[] = jobData?.data.map(
        (job: any) => ({
          _id: job._id,
          jobName: job?.title,
          jobUrl: job?.link,
          content: job?.content,
          jobImage: job?.attachments[0]?.path,
          _userId: job?._userId as unknown as string,
          jobQualification: job?.jobQualification,
          jobLocation: job?.jobLocation,
          jobOpening: job?.jobOpening,
          jobExperience: job?.jobExperience,
          jobAmount: job?.jobAmount,
          jobSkills: job?.jobSkills,
          jobType: job?.jobType,
          jobTenure: job?.jobTenure,
          jobContactName: job?.jobContactName,
          jobContactNumber: job?.jobContactNumber,
          jobContactEmail: job?.jobContactEmail,
          jobDeadLine: job?.endedOn,
          createdOn: job?.createdOn,
          plainContent: job?.plainContent,
          jobId: job?.jobId,
          laptop: job?.laptop,
        })
      );
      const sortedJobs = formattedJobData
        .sort(
          (a, b) =>
            new Date(b?.createdOn).getTime() - new Date(a.createdOn).getTime()
        )
        .slice(0, 5);

      setJobs(sortedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className={styles['dashboard-jobs-parent']}>
        <div className={styles['dashboard-jobs-header']}>
          Job / Assignment Openings
        </div>
        {jobs.map((dashboardJobs) => (
          <div
            key={dashboardJobs?._id}
            className={styles['dashboard-jobs-wrapper']}
            onClick={() => openDetailsModal(dashboardJobs)}
            style={{ cursor: 'pointer' }}
          >
            <div className={styles['dashboard-jobs-deatils-wrapper']}>
              <div className={styles['dashboard-jobs-title']}>
                <img
                  src={
                    dashboardJobs?.jobImage
                      ? resolveBEAsset(dashboardJobs?.jobImage)
                      : '/images/eventsDefaultImage.png'
                  }
                  alt="jobImage- team WCP"
                />

                <div className={styles['dashboard-job-value']}>
                  {dashboardJobs?.jobName}
                </div>
              </div>

              <div className={styles['dashboard-jobs-loc-quali']}>
                <div className={styles['dashboard-jobs-key-value-skills']}>
                  <div className={styles['dashboard-job-key']}>
                    <img
                      src="/images/certificate-file.svg"
                      alt="certifiedFile-icon"
                      height={16}
                    />
                  </div>
                  <div className={styles['dashboard-job-value']}>
                    {dashboardJobs?.jobQualification}
                  </div>
                </div>
                <div className={styles['dashboard-jobs-key-value']}>
                  <div className={styles['dashboard-job-key']}>
                    <FontAwesomeIcon icon={faLocationDot} color="#666666" />
                  </div>
                  <div className={styles['dashboard-job-value']}>
                    {dashboardJobs?.jobLocation.includes(',')
                      ? 'Multiple Locations'
                      : dashboardJobs?.jobLocation}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className={styles['dashboard-jobs-readMore']}
          onClick={() => navigate('/jobs')}
        >
          <span>See more &gt;&gt; </span>
        </div>
      </div>
      {detailsModalOpen && selectedJob && (
        <JobDetailsModal
          isOpen={detailsModalOpen}
          onClose={closeDetailsModal}
          job={selectedJob}
        />
      )}
    </>
  );
}
